const LevaturModel = {
    levatur_default: '1',
    levatur_shLeft_weakRight: '2',
    levatur_shRight_weakLeft: '3',
    levatur_shLeft_weak: '4',
    levatur_shRight_weak: '5',
    levatur_shLeft_weakLight: '6',
    levatur_shRight_weakLight: '7',
    levatur_shLeft_weakLeftLight: '8',
    levatur_shLeft_weakRightLight: '9',
    levatur_shRight_weakLeftLight: '10',
    levatur_shRight_weakRightLight: '11',
    getLevaturString: (levatur) => {
        switch (levatur) {
            case LevaturModel.levatur_default:
                return 'без корекция';
            case LevaturModel.levatur_shLeft_weakRight:
                return 'скъсяване отляво, слабост отдясно';
            case LevaturModel.levatur_shRight_weakLeft:
                return 'скъсяване отдясно, слабост отляво';
            case LevaturModel.levatur_shLeft_weak:
                return 'скъсяване отляво, слабост';
            case LevaturModel.levatur_shRight_weak:
                return 'скъсяване отдясно, слабост';
            case LevaturModel.levatur_shLeft_weakLight:
                return 'скъсяване отляво, лека слабост';
            case LevaturModel.levatur_shRight_weakLight:
                return 'скъсяване отдясно, лека слабост';
            case LevaturModel.levatur_shLeft_weakLeftLight:
                return 'скъсяване отляво, лека слабост отляво';
            case LevaturModel.levatur_shLeft_weakRightLight:
                return 'скъсяване отляво, лека слабост отдясно';
            case LevaturModel.levatur_shRight_weakLeftLight:
                return 'скъсяване отдясно, лека слабост отляво';
            case LevaturModel.levatur_shRight_weakRightLight:
                return 'скъсяване отдясно, лека слабост отдясно';
        }
    }
};

export default LevaturModel;