import { createBrowserRouter, RouterProvider, useLocation, useNavigate } from 'react-router-dom';

import { checkIsLoggedIn, checkUser } from './store/auth-context';

import UserProfile from './pages/Profile/UserProfile';
import Logout from './pages/Login/Logout';
import AuthPage from './pages/Login/AuthPage';

import NotFound from './pages/NotFound';
import RootLayout from './pages/Root';
import ProtectedLayout from './pages/Protected';
import DiagnosticsSheetList from './pages/Diagnostics/DiagnosticsSheetList';
import DiagnosticsSheetDetails from './pages/Diagnostics/DiagnosticsSheetDetails';
import PatientsList from './pages/Patients/PatientsList';
import PatientDetails from './pages/Patients/PatientDetails';
import { Api, userRoles } from './utils/api';
import { convertToProperDate } from './utils/utils';
import PatientForm from './pages/Patients/PatientForm';
import UsersList from './pages/Users/UsersList';
import UserDetails from './pages/Users/UserDetails';
import UserForm from './pages/Users/UserForm';
import DiagnosticsForm from './pages/Diagnostics/DiagnosticsForm';
import CompaniesList from './pages/Companies/CompaniesList';
import CompanyDetails from './pages/Companies/CompanyDetails';
import CompanyForm from './pages/Companies/CompanyForm';

export const router = createBrowserRouter([
  { path: '/', 
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [
    { path: 'auth', element: <AuthPage />},
    { path: '/', 
    element: <ProtectedLayout />,
    children: [
      { 
        index: true,
        element: <DiagnosticsSheetList />,
        loader: async () => {
          try {
            const data = await Api.getDiagnostics({}, 1, 10);
            return {diagnostics: data, errors: []};
          } catch (e) {
            return {diagnostics: {total: 0, diagnostics: []}, errors: e.errors};
          }
        }
      },
      { 
        path: 'profile', 
        element: <UserProfile />
      },
      { 
        path: 'logout', 
        element: <Logout />
      },
      { 
        path: 'companies',
        id: 'companies',
        loader: () => {return checkUser([userRoles.superAdmin]);},
        children: [
          {
            index: true,
            element: <CompaniesList />,
            loader: async () => {
              try {
                const data = await Api.getCompanies('', 1, 10);
                return {companies: data, errors: []};
              } catch (e) {
                return {companies: {total: 0, companies: []}, errors: e.errors};
              }
            }
          },
          { path: ':companyId',
            id: 'company-details',
            element: <CompanyDetails />,
            loader: async ({ params }) => {
              try {
                const data = await Api.getCompanyDetails(params.companyId);
                return {company: data, errors: []};
              } catch (e) {
                return {company: {}, errors: e.errors};
              }
            }
          },
          { path: ':companyId/edit',
            element: <CompanyForm />,
            loader: async ({ params }) => {
              try {
                const data = await Api.getCompanyDetails(params.companyId);
                return {company: data, meta: {title: 'Редактиране на фирма', submitButtonTitle: 'Запази'}, errors: []};
              } catch (e) {
                return {company: {}, meta: {title: 'Редактиране на фирма', submitButtonTitle: 'Запази'}, errors: e.errors};
              }
            }
          },
          { 
            path: 'new', 
            element: <CompanyForm />,
            loader: () => {
              try {
                return {company: {id: '', name: '', description: '', logo: ''}, meta: {title: 'Нова фирма', submitButtonTitle: 'Добави', action: 'new'}, errors: []};
              } catch (e) {
                return {company: {}, meta: {title: 'Нова фирма', submitButtonTitle: 'Добави'}, errors: e.errors};
              }
            }
          }
        ],
      },
      { 
        path: 'users',
        id: 'users',
        loader: () => {return checkUser([userRoles.superAdmin, userRoles.admin]);},
        children: [
          {
            index: true,
            element: <UsersList />,
            loader: async () => {
              try {
                const data = await Api.getUsers('', 1, 10);
                return {users: data, errors: []};
              } catch (e) {
                return {users: {total: 0, users: []}, errors: e.errors};
              }
            }
          },
          { path: ':userId',
            id: 'user-details',
            element: <UserDetails />,
            loader: async ({ params }) => {
              try {
                const data = await Api.getUserDetails(params.userId);
                return {user: data, errors: []};
              } catch (e) {
                return {user: {}, errors: e.errors};
              }
            }
          },
          { path: ':userId/edit',
            element: <UserForm />,
            loader: async ({ params }) => {
              try {
                const data = await Api.getUserDetails(params.userId);
                return {user: data, meta: {title: 'Редактиране на потребител', submitButtonTitle: 'Запази', action: 'edit'}, errors: []};
              } catch (e) {
                return {user: {}, meta: {title: 'Редактиране на потребител', submitButtonTitle: 'Запази', action: 'edit'}, errors: e.errors};
              }
            }
          },
          { 
            path: 'new', 
            element: <UserForm />,
            loader: () => {
              try {
                return {user: {id: '', role: '', name: '', family: '', email: '', password: '', dateAdded: '', lastModified: '', company: ''}, meta: {title: 'Нов потребител', submitButtonTitle: 'Добави', action: 'new'}, errors: []};
              } catch (e) {
                return {user: {}, meta: {title: 'Нов потребител', submitButtonTitle: 'Добави', action: 'new'}, errors: e.errors};
              }
            }
          }
        ],
      },
      { 
        path: 'patients',
        id: 'patients',
        children: [
          {
            index: true,
            element: <PatientsList />,
            loader: async () => {
              try {
                const data = await Api.getPatients('', 1, 10);
                return {patients: data, errors: []};
              } catch (e) {
                return {patients: {total: 0, patients: []}, errors: e.errors};
              }
            }
          },
          { path: ':patientId',
            id: 'patient-details',
            element: <PatientDetails />,
            // loader: async ({ params }) => {
            //   return {patients: await Api.getPatientDetails(params.patientId), diagnostics: await Api.getDiagnostics({patientId: params.patientId}, 1, 10)};
            // },
            loader: async ({ params }) => {
              try {
                const data = await Api.getPatientDetails(params.patientId);
                return {patients: data, errors: []};
              } catch (e) {
                return {patients: {}, errors: e.errors};
              }
            }
          },
          { path: ':patientId/edit',
            element: <PatientForm />,
            // loader: async ({ params }) => {
            //   return {patient: await Api.getPatientDetails(params.patientId), meta: {title: 'Редактиране на пациент', submitButtonTitle: 'Запази'}};
            // }
            loader: async ({ params }) => {
              try {
                const data = await Api.getPatientDetails(params.patientId);
                return {patient: data, meta: {title: 'Редактиране на пациент', submitButtonTitle: 'Запази'}, errors: []};
              } catch (e) {
                return {patient: {}, meta: {title: 'Редактиране на пациент', submitButtonTitle: 'Запази'}, errors: e.errors};
              }
            }
          },
          { 
            path: 'new', 
            element: <PatientForm />,
            // loader: () => {
            //   return {patient: {id: '', name: '', family: '', jobTitle: '', sex: '', age: '', dateAdded: '', lastModified: ''}, meta: {title: 'Нов пациент', submitButtonTitle: 'Добави'}};
            // }
            loader: () => {
              try {
                return {patient: {id: '', name: '', family: '', jobTitle: '', sex: '', age: '', dateAdded: '', lastModified: ''}, meta: {title: 'Нов пациент', submitButtonTitle: 'Добави'}, errors: []};
              } catch (e) {
                return {patient: {}, meta: {title: 'Нов пациент', submitButtonTitle: 'Добави'}, errors: e.errors};
              }
            }
          }
        ],
      },

      { 
        path: 'diagnostics',
        id: 'diagnostics',
        children: [
          {
            index: true,
            element: <DiagnosticsSheetList />,
            loader: async () => {
              try {
                const data = await Api.getDiagnostics({}, 1, 10);
                return {diagnostics: data, errors: []};
              } catch (e) {
                return {diagnostics: {total: 0, diagnostics: []}, errors: e.errors};
              }
            }
          },
          { path: ':sheetId',
            id: 'diagnostics-details',
            element: <DiagnosticsSheetDetails />,
            loader: async ({ params }) => {
              try {
                const data = await Api.getDiagnosticDetails(params.sheetId);
                return {diagnostic: data, errors: []};
              } catch (e) {
                return {diagnostic: {}, errors: e.errors};
              }
            }
          },
          { path: ':sheetId/edit',
            id: 'diagnostics-form',
            element: <DiagnosticsForm />,
            loader: async ({ params }) => {
              try {
                const data = await Api.getDiagnosticDetails(params.sheetId);
                return {diagnostic: data, meta: {title: 'Редактиране на диагностика', action: 'edit'}, errors: []};
              } catch (e) {
                return {diagnostic: {}, meta: {title: 'Редактиране на диагностика', action: 'edit'}, errors: e.errors};
              }
            }
          },
          { 
            path: 'new', 
            element: <DiagnosticsForm />,
            loader: () => {
              try {
                return {diagnostic: {
                  id: '',
                  dateAdded: new Date(),
                  lastModified: new Date(),
                  patient: {
                    id: '', 
                    name: '',
                    age: null,
                    jobTitle: '',
                    sex: '',
                  },
                  vitals: {
                    height: null,
                    weight: null,
                    bloodPressure: '',
                    pulse: null,
                    saturation: null,
                    medHistory: ''
                  },
                  blood: [
                    {label: 'Mg', result: '', units: '', conclusion: ''},
                    {label: 'B12', result: '', units: '', conclusion: ''},
                    {label: 'Ca', result: '', units: '', conclusion: ''},
                    {label: 'D3', result: '', units: '', conclusion: ''},
                  ],
                  caliper: {
                    subscapula: null,
                    biceps: null,
                    triceps: null,
                    nadialic: null,
                  },
                  bodyScales: {
                    fat: null,
                    fatEval: null,
                    hydration: null,
                    hydrationEval: null,
                    muscles: null,
                    musclesEval: null,
                    bones: null,
                    basalMetabolism: null,
                    activeMetabolism: null,
                  },
                  feet: {
                    image:'',
                    recommendations: '',
                    varus:'',
                    supPro: {left: '', right: ''},
                    left: [],
                    right: [],
                    arch: {left: null, right: null},
                  },
                  spine: {
                    neck: {
                      flexion: null,
                      extension: null,
                      lateral: {left: null, right: null},
                      rotation: {left: null, right: null},
                    },
                    curvature: [],
                    cervical: '',
                    grip: {left: null, right: null},
                    handedness: '',
                    thoracic: {curv: [], degrees: null, direction: ''},
                    collarBone: {degrees: null, direction: '', leftNote: '', rightNote: ''},
                    shoulders: {degrees: null, direction: '', leftNote: '', rightNote: ''},
                    lumbar: {lord: '', curv: [], degrees: null, direction: ''},
                    lateral: {left: null, right: null},
                    c7s1: null,
                    th12: null,
                    c7th12: null,
                    scoliometerNote: ''
                  },
                  goniometry: {
                    hip: {
                      left: {extension: null, flexion: null, flexionStrKnee: null, abduction: null, adduction: null, rotationInner: null, rotationOuter: null},
                      right: {extension: null, flexion: null, flexionStrKnee: null, abduction: null, adduction:null, rotationInner: null, rotationOuter: null},
                    },
                    shoulder: {
                      left: {extension: null, flexion: null, abduction: null, adduction: null, rotationInner: null, rotationOuter: null},
                      right: {extension: null, flexion: null, abduction: null, adduction: null, rotationInner: null, rotationOuter: null},
                    },
                    ankle: {
                      left: {extension: null, flexion: null},
                      right: {extension: null, flexion: null},
                    },
                    knee: {
                      left: {extension: null, flexion: null},
                      right: {extension: null, flexion: null},
                    },
                    elbow: {
                      left: {extension: null, flexion: null, supination: null, pronation: null},
                      right: {extension: null, flexion: null, supination: null, pronation: null},
                    },
                    wrist: {
                      left: {extension: null, flexion: null, radial: null, ulnar: null},
                      right: {extension: null, flexion: null, radial: null, ulnar: null},
                    },
                  },
                  elasticity: {
                    abdomen: '',
                    levatur: '',
                  },
                  centimetrics: {
                    hip: {left: null, right:null},
                    shank: {left: null, right:null},
                    shoulders: null,
                    neck: null,
                    abdomen: null,
                    chest: null,
                    biceps: {left: null, right:null},
                    forearm: {left: null, right:null},
                  },
                  boneLength: {
                    femur: {left: null, right: null},
                    tibia: {left: null, right: null},
                    humerus: {left: null, right: null},
                    radius: {left: null, right: null},
                  },
                  forcemetry: {
                    backExt: null,
                    trapezius: {left: null, right: null},
                    neckExt: null,
                    neckFlex: null,
                    neckLateral: {left: null, right: null},
                    teresMajor: {left: null, right: null},
                    teresMinor: {left: null, right: null},
                    biceps: {left: null, right: null},
                    triceps: {left: null, right: null},
                    wristExt: {left: null, right: null},
                    wristFlex: {left: null, right: null},
                    latDorsi: {left: null, right: null},
                    shoulderFront: {left: null, right: null},
                    shoulderMid: {left: null, right: null},
                    shoulderBack: {left: null, right: null},
                    chest: {left: null, right: null},
                    abdomen: null,
                    waistLat: {left: null, right: null},
                    gluteus: {left: null, right: null},
                    backHip: {left: null, right: null},
                    shank: {left: null, right: null},
                    hip: {left: null, right: null},
                    tibialis: {left: null, right: null},
                    abductors: {left: null, right: null},
                    adductors: {left: null, right: null},
                  },
                  thermography: {
                    spine: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    neck: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    thoracic: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    lumbar: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    sacral: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    rArmLat: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    rArmMed: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    lArmLat: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    lArmMed: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    elbowLat: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    elbowMed: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    wristSup: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    wristPro: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    shoulderFront: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    shoulderBack: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    rLegFront: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    lLegFront: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    kneeFront: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    hip: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    ankle: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    rLegBack: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    lLegBack: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    kneeBack: {note: '', degreesMin: null, degreesMax: null, image: ''},
                    achilles: {note: '', degreesMin: null, degreesMax: null, image: ''},
                  },
                  sensitivity: {
                    note: '',
                    svgType: null,
                    left: {
                      f1: '',
                      f2: '',
                      f3: '',
                      f4: '',
                      f5: '',
                      f6: '',
                      f7: '',
                      f8: '',
                      f9: '',
                      f10: '',
                      f11: '',
                      f12: '',
                      f13: '',
                      f14: '',
                      f15: '',
                      f16: '',
                      f17: '',
                      f18: '',
                      f19: '',
                      f20: '',
                      f21: '',
                      f22: '',
                      f23: '',
                      f24: '',
                      f25: '',
                      b1: '',
                      b2: '',
                      b3: '',
                      b4: '',
                      b5: '',
                      b6: '',
                      b7: '',
                      b8: '',
                      b9: '',
                      b10: '',
                      b11: '',
                      b12: '',
                      b13: '',
                      b14: '',
                      b15: '',
                      b16: '',
                      b17: '',
                      b18: '',
                      b19: '',
                      b20: '',
                      b21: '',
                      b22: '',
                      b23: '',
                      b24: '',
                      b25: '',
                    },
                    right: {
                      f1: '',
                      f2: '',
                      f3: '',
                      f4: '',
                      f5: '',
                      f6: '',
                      f7: '',
                      f8: '',
                      f9: '',
                      f10: '',
                      f11: '',
                      f12: '',
                      f13: '',
                      f14: '',
                      f15: '',
                      f16: '',
                      f17: '',
                      f18: '',
                      f19: '',
                      f20: '',
                      f21: '',
                      f22: '',
                      f23: '',
                      f24: '',
                      f25: '',
                      b1: '',
                      b2: '',
                      b3: '',
                      b4: '',
                      b5: '',
                      b6: '',
                      b7: '',
                      b8: '',
                      b9: '',
                      b10: '',
                      b11: '',
                      b12: '',
                      b13: '',
                      b14: '',
                      b15: '',
                      b16: '',
                      b17: '',
                      b18: '',
                      b19: '',
                      b20: '',
                      b21: '',
                      b22: '',
                      b23: '',
                      b24: '',
                      b25: '',
                    }
                  },
                  xRay: [
                    {base64Image: ''},
                  ]
            },  meta: {title: 'Нова диагностика', action: 'new'}}
          } catch (e) {
            return {diagnostic: {}, meta: {title: 'Нова диагностика', action: 'new'}, errors: e.errors};
          }
            },
          }
        ],
      },
    ],
    loader: checkIsLoggedIn
  }
  ]
  }
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
};

export default App;
