import React, {memo, useContext, useEffect} from 'react';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLoaderData, useNavigate, useOutletContext } from 'react-router-dom';
import { Api } from '../../utils/api';
import ElasticitySelect from './DiagnosticsElasticitySelect';
import Feet from '../../models/Feet';
import Spine from '../../models/Spine';
import CurvModel from '../../models/Curv';
import CervicalModel from '../../models/Cervical';
import LumbarModel from '../../models/Lumbar';
import FieldsModel from '../../models/Fields';
import {leftBack, leftFront, rightBack, rightFront} from '../../constants/Sensitivity';
import 'rc-slider/assets/index.css';
import Modal from '../../components/UI/Modal';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import selectCustomStyles from '../../components/UI/SelectCustomStyles';
import DiagnosticsSheetData from './DiagnosticsSheetData';
import DatePicker from 'react-date-picker';
import {
    convertBase64,
    cvtDateToString,
    cvtStringToDate,
    musclesEvaluation,
    musclesEvaluationLatRot,
    musclesEvaluationLatRotShortening, musclesEvaluationLatRotWeakness, musclesEvaluationShortening,
    musclesEvaluationSingle, musclesEvaluationSingleShortening, musclesEvaluationSingleWeakness
} from '../../utils/utils';
import AuthContext from '../../store/auth-context';
import ReactSlider from 'react-slider'

import neck from './Images/neck.jpg';
import femur from './Images/femur.jpg';
import tibia from './Images/tibia.jpg';
import humerus from './Images/humerus.jpg';
import radius from './Images/radius.jpg';
import hip from './Images/hip.jpg';
import shank from './Images/shank.jpg';
import chest from './Images/chest.jpg';
import abdomen from './Images/abdomen.jpg';
import shoulders from './Images/shoulders.jpg';
import biceps from './Images/biceps.jpg';
import forearm from './Images/forearm.jpg';
import neck_measurement from './Images/neck_measurement.jpg';
import musclesFront from './Images/muscles_front.jpg';
import musclesBack from './Images/muscles_back.jpg';
import varusNormal from './Images/varus_normal.jpg';
import varusBow from './Images/varus_bow.jpg';
import varusKnock from './Images/varus_knock.jpg';
import varusLBRK from './Images/varus_lbrk.jpg';
import varusLKRB from './Images/varus_lkrb.jpg';
import supProNeutral from './Images/neutral.jpg';
import supProSupination from './Images/supination.jpg';
import supProOverSupination from './Images/oversupination.jpg';
import supProPronation from './Images/pronation.jpg';
import supProOverPronation from './Images/overpronation.jpg';
import spineHLLKLC from './Images/spine_hyper_lord_lumb_kyph_lord_cerv.jpg';
import spineHLLK from './Images/spine_hyper_lord_lumb_kyph.jpg';
import spineHLL from './Images/spine_hyper_lord_lumb.jpg';
import spineK from './Images/spine_kyph.jpg';
import spineLCFLL from './Images/spine_lord_cerv_flatback_lord_lumb.jpg';
import spineLCHLL from './Images/spine_lord_cerv_hyper_lord_lumb.jpg';
import spineLCK from './Images/spine_lord_cerv_kyph.jpg';
import spineLCLL from './Images/spine_lord_cerv_lord_lumb.jpg';
import spineLC from './Images/spine_lord_cerv.jpg';
import spineLLKLC from './Images/spine_lord_lumb_kyph_lord_cerv.jpg';
import spineLLK from './Images/spine_lord_lumb_kyph.jpg';
import spineLL from './Images/spine_lord_lumb.jpg';
import spineN from './Images/spine_norm.jpg';
import spineSLLTL from './Images/spine_scol_lumb_left_thor_left.jpg';
import spineSLLTR from './Images/spine_scol_lumb_left_thor_right.jpg';
import spineSLL from './Images/spine_scol_lumb_left.jpg';
import spineSLRTL from './Images/spine_scol_lumb_right_thor_left.jpg';
import spineSLRTR from './Images/spine_scol_lumb_right_thor_right.jpg';
import spineSLR from './Images/spine_scol_lumb_right.jpg';
import spineSTL from './Images/spine_scol_thor_left.jpg';
import spineSTR from './Images/spine_scol_thor_right.jpg';
import jointsAnkle from './Images/joints_ankle.jpg';
import jointsElbow from './Images/joints_elbow.jpg';
import jointsElbow1 from './Images/joints_elbow1.jpg';
import jointsHip from './Images/joints_hip.jpg';
import jointsHip1 from './Images/joints_hip1.jpg';
import jointsHip2 from './Images/joints_hip2.jpg';
import jointsKnee from './Images/joints_knee.jpg';
import jointsShoulder from './Images/joints_shoulder.jpg';
import jointsShoulder1 from './Images/joints_shoulder1.jpg';
import jointsShoulder2 from './Images/joints_shoulder2.jpg';
import jointsWrist from './Images/joints_wrist.jpg';
import jointsWrist1 from './Images/joints_wrist1.jpg';
import noXRayThumb from './Images/no_thumb.jpg';
import { getValue } from '@testing-library/user-event/dist/utils';
import ModalColorpicker from "../../components/UI/ModalColorpicker";
import InputLR from "../../components/UI/InputLR";
import ThermographyInput from "../../components/UI/ThermographyInput";
import LevaturModel from "../../models/Levatur";

var flatten = require('flat');
var unflatten = require('flat').unflatten;

const DiagnosticsForm = (props) => {

    const authCtx = useContext(AuthContext);
    const data = useLoaderData();
    const meta = data.meta;
    const diagnostics = data.diagnostic;

    const navigate = useNavigate();

    const defaultFormValues = flatten(diagnostics, {delimiter: '_', safe: true});
    const { register, watch, trigger, formState: { errors, isDirty }, handleSubmit, clearErrors, getValues, setValue, control} = useForm({
        defaultValues: defaultFormValues
      });



    const spineLumbarDirection = watch("spine_lumbar_direction");
    const [isScolLumbChecked, setIsScolLumbChecked] = useState(getValues('spine_lumbar_curv').includes('1'));
    const [isScolThorChecked, setIsScolThorChecked] = useState(getValues('spine_thoracic_curv').includes('1'));

    const [formStep, setFormStep] = useState(1);
    const [uploadedFile, setUploadedFile] = useState(getValues('feet_image'));
    //const [uploadedXRayFile, setUploadedXRayFile] = useState(getValues('spine_xRay'));
    const [thermSpineImg, setThermSpineImg] = useState(getValues('thermography_spine_image'));
    const [thermNeckImg, setThermNeckImg] = useState(getValues('thermography_neck_image'));
    const [thermThoracicImg, setThermThoracicImg] = useState(getValues('thermography_thoracic_image'));
    const [thermLumbarImg, setThermLumbarImg] = useState(getValues('thermography_lumbar_image'));
    const [thermSacralImg, setThermSacralImg] = useState(getValues('thermography_sacral_image'));
    const [thermRArmLatImg, setThermRArmLatImg] = useState(getValues('thermography_rArmLat_image'));
    const [thermRArmMedImg, setThermRArmMedImg] = useState(getValues('thermography_rArmMed_image'));
    const [thermLArmLatImg, setThermLArmLatImg] = useState(getValues('thermography_lArmLat_image'));
    const [thermLArmMedImg, setThermLArmMedImg] = useState(getValues('thermography_lArmMed_image'));
    const [thermElbowLatImg, setThermElbowLatImg] = useState(getValues('thermography_elbowLat_image'));
    const [thermElbowMedImg, setThermElbowMedImg] = useState(getValues('thermography_elbowMed_image'));
    const [thermWristSupImg, setThermWristSupImg] = useState(getValues('thermography_wristSup_image'));
    const [thermWristProImg, setThermWristProImg] = useState(getValues('thermography_wristPro_image'));
    const [thermShoulderFrontImg, setThermShoulderFrontImg] = useState(getValues('thermography_shoulderFront_image'));
    const [thermShoulderBackImg, setThermShoulderBackImg] = useState(getValues('thermography_shoulderBack_image'));
    const [thermRLegFrontImg, setThermRLegFrontImg] = useState(getValues('thermography_rLegFront_image'));
    const [thermLLegFrontImg, setThermLLegFrontImg] = useState(getValues('thermography_lLegFront_image'));
    const [thermKneeFrontImg, setThermKneeFrontImg] = useState(getValues('thermography_kneeFront_image'));
    const [thermHipImg, setThermHipImg] = useState(getValues('thermography_hip_image'));
    const [thermAnkleImg, setThermAnkleImg] = useState(getValues('thermography_ankle_image'));
    const [thermRLegBackImg, setThermRLegBackImg] = useState(getValues('thermography_rLegBack_image'));
    const [thermLLegBackImg, setThermLLegBackImg] = useState(getValues('thermography_lLegBack_image'));
    const [thermKneeBackImg, setThermKneeBackImg] = useState(getValues('thermography_kneeBack_image'));
    const [thermAchillesImg, setThermAchillesImg] = useState(getValues('thermography_achilles_image'));

    const [modalIsShown, setModalIsShown] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [fatSliderValue, setFatSliderValue] = useState(getValues('bodyScales_fatEval'));
    const [hydrationSliderValue, setHydrationSliderValue] = useState(getValues('bodyScales_hydrationEval'));
    const [musclesSliderValue, setMusclesSliderValue] = useState(getValues('bodyScales_musclesEval'));

    const handleFileRead = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setUploadedFile(base64);
        setValue('feet_image', base64);
    }

    const handleImageRead = async (event, field, setFieldFunc) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setFieldFunc(base64);
        setValue(field, base64);
    }

    // const handleXRayFileRead = async (event) => {
    //     const file = event.target.files[0];
    //     const base64 = await convertBase64(file);
    //     setUploadedXRayFile(base64);
    //     setValue('spine_xRay', base64);
    // }

    const { fields: xRayFiles, append: appendXRay, remove: removeXRay } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "xRay", // unique name for your Field Array
    });

    const handleXRayFileRead = async (e, field, index) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);

        // // Create a new object without the 'id' property
        // const updatedField = { ...xRayFiles[index], base64Image: base64 };
        // delete updatedField.id;
        //
        // // Update the specific array element directly
        // xRayFiles[index] = updatedField;
        //
        // // Trigger re-render by updating the form state
        // setValue("xRay", xRayFiles);

        // // Update the specific array element directly
        // xRayFiles[index].base64Image = base64;
        //
        // // Trigger re-render by updating the form state
        // setValue('xRay', xRayFiles);

        // Get the current state of the form
        const currentFields = watch("xRay");

        // Update the specific array element
        currentFields[index].base64Image = base64;

        // Set the updated array back to the form state
        setValue("xRay", currentFields);

        //setValue(`xRay.${index}.base64Image`, base64);

        // Assuming you have a state to store the files
        // Update the state with the chosen file
        // For example, if you have a state like const [uploadedFiles, setUploadedFiles] = useState([]);
        // you can update it like this:
            //const updatedFiles = [...xRayFiles];
            //updatedFiles[index].base64Image = base64;
        // assuming you have a state to store the files
        // setUploadedFiles(updatedFiles);

        // Perform any other operations you need with the file
    };

    // const handleXRayFileRead1 = (event) => {
    //     const uploadedFiles = Array.from(event.target.files);
    //
    //     const files = uploadedFiles.map((file) => ({
    //         file
    //     }));
    //
    //     append(files);
    // };

    // const handleFileRead1 = async (event, field, setFunc) => {
    //     const file = event.target.files[0];
    //     const base64 = await convertBase64(file);
    //     setFunc(base64);
    //     setValue(field, base64);
    // }

    const handleCheckboxChange = (e, str) => {
        const { value, checked } = e.target;
        const currentValues = getValues(str) || [];
        const newValues = checked
            ? [...currentValues, value]
            : currentValues.filter((v) => v !== value);
        setValue(str, newValues);
    };

    //svg
    const [selectedPart, setSelectedPart] = useState(null);
    const [sensitivityModalisShown, setSensitivityModalisShown] = useState(false);
    const [color, setColor] = useState({});

    const handlePartClick = (part) => {
        setSelectedPart(part);
        setSensitivityModalisShown(true);
    };

    const applyColor = (colorSet) => {
        //setColor({ ...color, [selectedPart]: colorSet });
        setValue(`sensitivity_${selectedPart}`, colorSet);
        setSensitivityModalisShown(false);
    };

    //tabs
    const setTabStep = (step) => {
        trigger(["vitals_medHistory", "patient_id", "dateAdded"]).then((isValid) => {
        if (isValid) {
            clearErrors(["vitals_medHistory", "patient_id", "dateAdded"]);
            setFormStep(step);
       }
        });
    }

    const onNextStep = () => {
        setFormStep((currentStep) => currentStep + 1);
    }

    const onPrevStep = () => {
        setFormStep((currentStep) => currentStep - 1);
    }

    const patientOptions = (inputValue) =>
        new Promise(async (resolve) => {
            const result = await Api.getPatients({name: inputValue});

            const options = [];
            result.patients.forEach((item) => {
                options.push({ value: item.id, label: [item.name, ' ', item.family, ' ', item.age, ' г.'], extra: [item.sex, item.jobTitle, item.dateAdded, item.lastModified] });
            });

            resolve(options);
        }
    );

    const spineCervicalOptions = [
        { value: '1', label: CervicalModel.getCervicalString('1') },
        { value: '2', label: CervicalModel.getCervicalString('2') },
        { value: '3', label: CervicalModel.getCervicalString('3') },
    ];

    const spineLumbarOptions = [
        { value: '1', label: LumbarModel.getLumbarString('1') },
        { value: '2', label: LumbarModel.getLumbarString('2') },
        { value: '3', label: LumbarModel.getLumbarString('3') },
    ];

    const elasticityLevaturOptions = [
        { value: '1', label: LevaturModel.getLevaturString('1') },
        { value: '2', label: LevaturModel.getLevaturString('2') },
        { value: '3', label: LevaturModel.getLevaturString('3') },
        { value: '4', label: LevaturModel.getLevaturString('4') },
        { value: '5', label: LevaturModel.getLevaturString('5') },
        { value: '6', label: LevaturModel.getLevaturString('6') },
        { value: '7', label: LevaturModel.getLevaturString('7') },
        { value: '8', label: LevaturModel.getLevaturString('8') },
        { value: '9', label: LevaturModel.getLevaturString('9') },
        { value: '10', label: LevaturModel.getLevaturString('10') },
        { value: '11', label: LevaturModel.getLevaturString('11') },
    ];

    const { fields: bloodIndicators, append: appendBloodIndicator, remove: removeBloodIndicator } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "blood", // unique name for your Field Array
      });

    if (isDirty) {
        setValue('lastModified', new Date());
    }

    const formValues = unflatten(getValues(), {delimiter: '_', safe: true});
    formValues.feet.image = uploadedFile;
    if (formValues.thermography) {
        formValues.thermography.spine.image = thermSpineImg;
        formValues.thermography.neck.image = thermNeckImg;
        formValues.thermography.thoracic.image = thermThoracicImg;
        formValues.thermography.lumbar.image = thermLumbarImg;
        formValues.thermography.sacral.image = thermSacralImg;
        formValues.thermography.rArmLat.image = thermRArmLatImg;
        formValues.thermography.rArmMed.image = thermRArmMedImg;
        formValues.thermography.lArmLat.image = thermLArmLatImg;
        formValues.thermography.lArmMed.image = thermLArmMedImg;
        formValues.thermography.elbowLat.image = thermElbowLatImg;
        formValues.thermography.elbowMed.image = thermElbowMedImg;
        formValues.thermography.wristSup.image = thermWristSupImg;
        formValues.thermography.wristPro.image = thermWristProImg;
        formValues.thermography.shoulderFront.image = thermShoulderFrontImg;
        formValues.thermography.shoulderBack.image = thermShoulderBackImg;
        formValues.thermography.rLegFront.image = thermRLegFrontImg;
        formValues.thermography.lLegFront.image = thermLLegFrontImg;
        formValues.thermography.kneeFront.image = thermKneeFrontImg;
        formValues.thermography.hip.image = thermHipImg;
        formValues.thermography.ankle.image = thermAnkleImg;
        formValues.thermography.rLegBack.image = thermRLegBackImg;
        formValues.thermography.lLegBack.image = thermLLegBackImg;
        formValues.thermography.kneeBack.image = thermKneeBackImg;
        formValues.thermography.achilles.image = thermAchillesImg;
    }

    //formValues.spine.xRay = uploadedXRayFile;

    // function removeEmptyFields(data) {
    //     Object.keys(data).forEach(key => {
    //       if (data[key] === '' || data[key] == null) {
    //         delete data[key];
    //       }
    //     });
    //   }

    const [serverErrors, setServerErrors] = useOutletContext(data.errors);

    const onSubmit = (diagnosticData) => {
        //display empty fields
        //const formValues = getValues();
        
        // console.log(getValues());
        const emptyFields = [];

        for (const fieldName in diagnosticData) {
            if ((!diagnosticData[fieldName] || diagnosticData[fieldName] == '') && FieldsModel[fieldName] && FieldsModel[fieldName] != '') {
                if (diagnosticData[fieldName] != 0 || (typeof diagnosticData[fieldName] == 'string' && diagnosticData[fieldName].trim() == '')) {
                    emptyFields.push(FieldsModel[fieldName]);
                }
            }
          }
        if (emptyFields.length > 0) {
            const emptyFieldsString = emptyFields.join(', ');

            const message = (
                <>
                  <h3>Непопълнени полета:</h3>
                  <p className="alert-smallerValue">{emptyFieldsString}</p>
                  <p>Сигурни ли сте, че искате да финализирате?</p>
                </>
              );
              setModalMessage(message);
          } else {
            const message = (
                <>
                  <h3>Няма непопълнени полета.</h3>
                  <p>Искате ли да финализирате?</p>
                </>
              );
            setModalMessage(message);
          }    


        // Open the modal window
        setModalIsShown(true);
      };

    const onFinish = (diagnosticData) => {
        diagnosticData.feet_image = uploadedFile;
        diagnosticData.thermography_spine_image = thermSpineImg;
        diagnosticData.thermography_neck_image = thermNeckImg;
        diagnosticData.thermography_thoracic_image = thermThoracicImg;
        diagnosticData.thermography_lumbar_image = thermLumbarImg;
        diagnosticData.thermography_sacral_image = thermSacralImg;
        diagnosticData.thermography_rArmLat_image = thermRArmLatImg;
        diagnosticData.thermography_rArmMed_image = thermRArmMedImg;
        diagnosticData.thermography_lArmLat_image = thermLArmLatImg;
        diagnosticData.thermography_lArmMed_image = thermLArmMedImg;
        diagnosticData.thermography_elbowLat_image = thermElbowLatImg;
        diagnosticData.thermography_elbowMed_image = thermElbowMedImg;
        diagnosticData.thermography_wristSup_image = thermWristSupImg;
        diagnosticData.thermography_wristPro_image = thermWristProImg;
        diagnosticData.thermography_shoulderFront_image = thermShoulderFrontImg;
        diagnosticData.thermography_shoulderBack_image = thermShoulderBackImg;
        diagnosticData.thermography_rLegFront_image = thermRLegFrontImg;
        diagnosticData.thermography_lLegFront_image = thermLLegFrontImg;
        diagnosticData.thermography_kneeFront_image = thermKneeFrontImg;
        diagnosticData.thermography_hip_image = thermHipImg;
        diagnosticData.thermography_ankle_image = thermAnkleImg;
        diagnosticData.thermography_rLegBack_image = thermRLegBackImg;
        diagnosticData.thermography_lLegBack_image = thermLLegBackImg;
        diagnosticData.thermography_kneeBack_image = thermKneeBackImg;
        diagnosticData.thermography_achilles_image = thermAchillesImg;

        //diagnosticData.spine_xRay = uploadedXRayFile;
        const unflattened = unflatten(diagnosticData, {delimiter: '_', safe: true});
        unflattened.dateAdded = cvtDateToString(unflattened.dateAdded);
        unflattened.lastModified = cvtDateToString(unflattened.lastModified);

        for (let i = unflattened.xRay.length-1; i >= 0 ; i--) {
            if (typeof unflattened.xRay[i].base64Image === 'object') {
                unflattened.xRay.splice(i, 1);
            }
        }

        Api.setDiagnostic(unflattened).then((res) => {
            navigate('/diagnostics/' + String(res.id));
            setServerErrors([]);
        },
        (errors) => {
            setServerErrors(errors.errors);
          });

        setModalIsShown(false);
    }

    function musclesEvaluationCentimetrics(centimetricsLeftValue, centimetricsRightValue, jointLeftValue, jointRightValue, goniometryType, min, max) {
        if (centimetricsLeftValue == centimetricsRightValue) {
            return musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue);
        } else {
            if (centimetricsLeftValue < centimetricsRightValue) {
                switch (musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue)) {
                    case '':
                    case 'в норма':
                    case 'слабост отляво':
                        return 'слабост отляво';
                        break;
                    case 'слабост':
                        return 'слабост повече отляво';
                        break;
                    case 'слабост отдясно':
                    case 'слабост повече отдясно':
                    case 'лека слабост':
                        return 'слабост';
                        break;
                    case 'слабост повече отляво':
                        return 'слабост повече отляво';
                        break;
                    case 'скъсена мускулатура':
                        return 'скъсена мускулатура, слабост отляво';
                        break;
                    case 'скъсяване отдясно':
                        return 'скъсяване отдясно, слабост отляво';
                        break;
                    case 'скъсяване отляво':
                        return 'скъсяване отляво, слабост отляво';
                        break;
                    case 'скъсяване отдясно, слабост отляво':
                        return 'скъсяване отляво, слабост отляво';
                        break;
                    case 'скъсяване отляво, слабост отдясно':
                        return 'скъсяване отляво, слабост';
                        break;
                    case 'скъсяване повече отляво':
                        return 'скъсяване повече отляво, слабост отляво';
                        break;
                     case 'скъсяване повече отдясно':
                        return 'скъсяване повече отдясно, слабост отляво';
                        break;
                }
            } else {
                switch (musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue)) {
                    case '':
                    case 'в норма':
                    case 'слабост отдясно':
                        return 'слабост отдясно';
                        break;
                    case 'слабост':
                        return 'слабост повече отдясно';
                        break;
                    case 'слабост отляво':
                    case 'слабост повече отляво':
                    case 'лека слабост':
                        return 'слабост';
                        break;
                    case 'слабост повече отдясно':
                        return 'слабост повече отдясно';
                        break;
                    case 'скъсена мускулатура':
                        return 'скъсена мускулатура, слабост отдясно';
                        break;
                    case 'скъсяване отдясно':
                        return 'скъсяване отдясно, слабост отдясно';
                        break;
                    case 'скъсяване отляво':
                        return 'скъсяване отляво, слабост отдясно';
                        break;
                    case 'скъсяване отдясно, слабост отляво':
                        return 'скъсяване отдясно, слабост';
                        break;
                    case 'скъсяване отляво, слабост отдясно':
                        return 'скъсяване отляво, слабост отдясно';
                        break;
                    case 'скъсяване повече отляво':
                        return 'скъсяване повече отляво, слабост отдясно';
                        break;
                     case 'скъсяване повече отдясно':
                        return 'скъсяване повече отдясно, слабост отдясно';
                        break;
            };
        }
        }
    };

    // State to track the value of elasticity_levatur
    const [elasticityLevatur, setElasticityLevatur] = useState(getValues('elasticity_levatur') || '');

    // Handle select change and set state
    const handleSelectChange = (selectedOption) => {
        setElasticityLevatur(selectedOption.value);
    };

        return (
        <>
            {sensitivityModalisShown &&
                <ModalColorpicker message='Избери степен на чувствителност:' confirmButton='Приложи' cancelButton='Отказ' onCancel={() => {setSensitivityModalisShown(false);}} onPick={applyColor}></ModalColorpicker>}

            {modalIsShown && 
                <Modal message={modalMessage} confirmButton='Финализирай' cancelButton='Върни се и редактирай' onCancel={() => {setModalIsShown(false);}} onConfirm={handleSubmit(onFinish)}></Modal>}

            <h1>{meta.title}</h1>
            <form className='form-diagnostics' onSubmit={handleSubmit(onSubmit)}>
            <section className="lists">
                {formStep != 13 && <>
                <p className='control'>
                    <label>Дата:</label>
                </p>
                <div style={{marginBottom:'0.5em'}}>
                <Controller
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            locale="bg-BG"
                            format="dd.MM.y"
                            value={typeof field.value === "string" ? cvtStringToDate(field.value) : field.value}
                            onChange={(date) => {
                                clearErrors("dateAdded");
                                field.onChange(date);                            }}
                        />
                    )}
                    name={'dateAdded'}
                    rules={{ required: true }}
                />
                </div>
                {errors.dateAdded && <p className='error-message'>Моля, изберете дата.</p>}
                <p className='control'>
                    <label>Пациент:</label>
                </p>
                <div className='control'>
                    <Controller
                        control={control}
                        render={({field:{onChange, value, name, ref}}) => (
                            <AsyncSelect
                                inputRef={ref}
                                //name={name}
                                cacheOptions
                                defaultValue={meta.action != 'new' && [{value: getValues('patient_id'), label: `${getValues('patient_name')} ${getValues('patient_family')} ${getValues('patient_age')} г.`}]}
                                placeholder={meta.action === 'new' && 'Избери пациент...'}
                                loadOptions={patientOptions}
                                onChange={(selectedOption) => {
                                    clearErrors("patient_id");
                                    onChange(selectedOption.value);
                                    setValue('patient_name', selectedOption.label[0]);
                                    setValue('patient_family', selectedOption.label[2]);
                                    setValue('patient_age', selectedOption.label[4]);
                                    setValue('patient_sex', selectedOption.extra[0]);
                                    setValue('patient_jobTitle', selectedOption.extra[1]);
                                    setValue('patient_dateAdded', selectedOption.extra[2]);
                                    setValue('patient_lastModified', selectedOption.extra[3]);
                                }}
                                styles={selectCustomStyles}
                            />
                            )}
                        name={'patient_id'}
                        rules={{ required: true }}
                    />
                </div></>}
                {errors.patient_id && <p className='error-message'>Моля, изберете пациент.</p>}
                <ul className="steps">
                    <li className={(formStep === 1) ? 'active' : ''} onClick={() => setTabStep(1)}>Витални</li>
                    <li className={(formStep === 2) ? 'active' : ''} onClick={() => setTabStep(2)}>Кръвни</li>
                    <li className={(formStep === 3) ? 'active' : ''} onClick={() => setTabStep(3)}>Калипер</li>
                    <li className={(formStep === 4) ? 'active' : ''} onClick={() => setTabStep(4)}>Метаболизъм</li>
                    <li className={(formStep === 5) ? 'active' : ''} onClick={() => setTabStep(5)}>Ходила</li>
                    <li className={(formStep === 6) ? 'active' : ''} onClick={() => setTabStep(6)}>Гръбнак</li>
                    <li className={(formStep === 7) ? 'active' : ''} onClick={() => setTabStep(7)}>Подвижност</li>
                    <li className={(formStep === 8) ? 'active' : ''} onClick={() => setTabStep(8)}>Силометрия</li>
                    <li className={(formStep === 9) ? 'active' : ''} onClick={() => setTabStep(9)}>Еластичност</li>
                    <li className={(formStep === 10) ? 'active' : ''} onClick={() => setTabStep(10)}>Сантиметрия</li>
                    <li className={(formStep === 11) ? 'active' : ''} onClick={() => setTabStep(11)}>Кости</li>
                    <li className={(formStep === 12) ? 'active' : ''} onClick={() => setTabStep(12)}>Термография</li>
                    <li className={(formStep === 13) ? 'active' : ''} onClick={() => setTabStep(13)}>Чувствителност</li>
                    <li className={(formStep === 14) ? 'active' : ''} onClick={() => setTabStep(14)}>Резултат</li>
                </ul>

                    {formStep === 1 && 
                        <>
                    <h2>Витални показатели</h2>
                    <p className='control'>
                        <label htmlFor='vitals_height'>Височина (см):</label>
                        <input
                            {...register("vitals_height", {valueAsNumber: true})}
                            name="vitals_height"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='vitals_weight'>Тегло (кг):</label>
                        <input
                            {...register("vitals_weight", {valueAsNumber: true})}
                            name="vitals_weight"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='vitals_bloodPressure'>Кръвно налягане:</label>
                        <input
                            {...register("vitals_bloodPressure")}
                            name="vitals_bloodPressure"
                            type="text"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='vitals_pulse'>Пулс:</label>
                        <input
                            {...register("vitals_pulse", {valueAsNumber: true})}
                            name="vitals_pulse"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='vitals_saturation'>Сатурация (%):</label>
                        <input
                            {...register("vitals_saturation", {valueAsNumber: true})}
                            name="vitals_saturation"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='vitals_medHistory'>Анамнеза:</label>
                        <input
                            {...register("vitals_medHistory", { required: true })}
                            className={errors.vitals_medHistory ? "error-input" : ""}
                            name="vitals_medHistory"
                            type="text"
                            onChange={(e) => {
                                clearErrors("vitals_medHistory");
                              }}
                        />
                    </p>
                    {errors.vitals_medHistory && <p className='error-message'>Моля, въведете анамнеза.</p>}
                    <div className='action'>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Кръвни ⇢</button>
                    </div>
                    </>
                    }

                    {formStep === 2 && (
                        <>
                    <h2>Лабораторни кръвни изследвания</h2>
                    {bloodIndicators.map((field, index) => (
                        <div key={field.id} className='wider multi-inp bottom-aligned'>
                            <div className='card bottom-aligned'>
                            <p className='control short'>
                                <label>Показател:</label>
                                <input
                                    // important to include key with field's id
                                    {...register(`blood.${index}.label`)} 
                                />
                            </p>
                            <p className='control short'>
                            <label htmlFor={`blood.${index}.result`}>Резултат:</label>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.result`)} 
                            />
                            </p>
                            </div>
                            <div className='card'>
                            <p className='control short'>
                            <label>Ед. и реф. ст-ти:</label>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.units`)} 
                            />
                            </p>
                            <div className='control short'>
                            <label>Заключение:</label>
                            <div style={{padding:'3px'}}>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.conclusion`)}
                                type="radio"
                                value="def"
                            /><span> ⬇️ </span>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.conclusion`)}
                                type="radio"
                                value="close-def"
                            /><span> ↙️ </span>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.conclusion`)}
                                type="radio"
                                value="ok"
                            /><span> 🆗 </span>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.conclusion`)}
                                type="radio"
                                value="ab"
                            /><span> ⬆️ </span>
                            </div>
                            </div>
                            </div>
                            <div className='card'>
                            <button type='button' className='del' onClick={() => removeBloodIndicator(index)}></button>
                            </div>
                        </div>
                    ))}
                    <div className='action'>
                        <button type='button' className='' onClick={() => appendBloodIndicator({
                            label: '',
                            // result: null
                            result: '',
                            units: '',
                            conclusion: ''
                        })}>Добави</button>     
                    </div>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Витални</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Калипер ⇢</button>
                    </div>
                    </>)}

                    {formStep === 3 && (
                        <>
                    <h2>Измерване с калипер</h2>
                    <p className='control'>
                        <label htmlFor='caliper_subscapula'>Подскапула (мм):</label>
                        <input
                            {...register("caliper_subscapula", {valueAsNumber: true})}
                            name="caliper_subscapula"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='caliper_biceps'>Бицепс (мм):</label>
                        <input
                            {...register("caliper_biceps", {valueAsNumber: true})}
                            name="caliper_biceps"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='caliper_triceps'>Трицепс (мм):</label>
                        <input
                            {...register("caliper_triceps", {valueAsNumber: true})}
                            name="caliper_triceps"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='caliper_nadialic'>Надиалична (мм):</label>
                        <input
                            {...register("caliper_nadialic", {valueAsNumber: true})}
                            name="caliper_nadialic"
                            type="number"
                            step="any"
                        />
                    </p>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Кръвни</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Метаболизъм ⇢</button>
                    </div>
                    </>)}

                    {formStep === 4 && (
                        <>
                    <h2>Метаболизъм</h2>
                    <p className='control'>
                        <label htmlFor='bodyScales_fat'>Мазнини (%):</label>
                        <input
                            {...register("bodyScales_fat", {valueAsNumber: true})}
                            name="bodyScales_fat"
                            type="number"
                            step="any"
                        />
                    </p>
                    <div style={{marginTop: '25px'}}>
                    <input
                            {...register("bodyScales_fatEval")}
                            name="bodyScales_fatEval"
                            type="hidden"
                        />
                    <ReactSlider
                        value={fatSliderValue}
                        ariaLabelledby="slider-label"
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        onAfterChange={(value) => {setFatSliderValue(value); setValue('bodyScales_fatEval', value)}
                        }
                    />
                    <div className='scale' style={{clipPath:'polygon(16px 0, 98.5% 0, 98.5% 100%, 16px 100%)'}}>
                        <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                    </div>
                    </div>
                    <p className='control'>
                        <label htmlFor='bodyScales_hydration'>Хидратация (%):</label>
                        <input
                            {...register("bodyScales_hydration", {valueAsNumber: true})}
                            name="bodyScales_hydration"
                            type="number"
                            step="any"
                        />
                    </p>
                    <div style={{marginTop: '25px'}}>
                    <input
                            {...register("bodyScales_hydrationEval")}
                            name="bodyScales_hydrationEval"
                            type="hidden"
                        />
                    <ReactSlider
                        value={hydrationSliderValue}
                        ariaLabelledby="slider-label"
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        onAfterChange={(value) => {setHydrationSliderValue(value); setValue('bodyScales_hydrationEval', value)}
                        }
                    />
                    <div className='scale' style={{clipPath:'polygon(16px 0, 98.5% 0, 98.5% 100%, 16px 100%)'}}>
                        <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                    </div>
                    </div>
                    <p className='control'>
                        <label htmlFor='bodyScales_muscles'>Мускули (%):</label>
                        <input
                            {...register("bodyScales_muscles", {valueAsNumber: true})}
                            name="bodyScales_muscles"
                            type="number"
                            step="any"
                        />
                    </p>
                    <div style={{marginTop: '25px'}}>
                    <input
                            {...register("bodyScales_musclesEval")}
                            name="bodyScales_musclesEval"
                            type="hidden"
                        />
                    <ReactSlider
                        value={musclesSliderValue}
                        ariaLabelledby="slider-label"
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        onAfterChange={(value) => {setMusclesSliderValue(value); setValue('bodyScales_musclesEval', value)}
                        }
                    />
                    <div className='scale' style={{clipPath:'polygon(16px 0, 98.5% 0, 98.5% 100%, 16px 100%)'}}>
                        <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                    </div>
                    </div>
                    <p className='control'>
                        <label htmlFor='bodyScales_bones'>Костна маса (кг):</label>
                        <input
                            {...register("bodyScales_bones", {valueAsNumber: true})}
                            name="bodyScales_bones"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='bodyScales_basalMetabolism'>Базален метаболизъм (Kcal):</label>
                        <input
                            {...register("bodyScales_basalMetabolism", {valueAsNumber: true})}
                            name="bodyScales_basalMetabolism"
                            type="number"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='bodyScales_activeMetabolism'>Активен метаболизъм (Kcal):</label>
                        <input
                            {...register("bodyScales_activeMetabolism", {valueAsNumber: true})}
                            name="bodyScales_activeMetabolism"
                            type="number"
                            step="any"
                        />
                    </p>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Калипер</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Ходила ⇢</button>
                    </div>
                    </>)}

                    {formStep === 5 && (
                        <>
                    <h2>Диагностика на ходилата</h2>
                    <p className='control' style={{marginBottom:'1em'}}>
                        <label htmlFor='feet_image'>Снимка:</label>
                        <input
                            {...register("feet_image")}
                            name="feet_image"
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={e => handleFileRead(e)}
                        />
                        <img src={uploadedFile} style={{maxWidth: '300px', maxHeight: '300px', margin: '1em 0'}}/>
                    </p>
                    <p className='control'>
                        <label>Ляво ходило:</label>
                    </p>
                    <div className="radio-img-wrapper sup-pro">
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="lneutral" value="neutral" />
                            <label htmlFor="lneutral">
                            <img src={supProNeutral} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="lsupination" value="supination" />
                            <label htmlFor="lsupination">
                            <img src={supProSupination} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="loversupination" value="overSupination" />
                            <label htmlFor="loversupination">
                            <img src={supProOverSupination} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="loverpronation" value="overPronation" />
                            <label htmlFor="loverpronation">
                            <img src={supProOverPronation} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="lpronation" value="pronation" />
                            <label htmlFor="lpronation">
                            <img src={supProPronation} />
                            </label>
                        </div>
                    </div>
                    <p className='left-short'>
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_norm} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("1")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_norm)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_sup} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("2")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_sup)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_pron} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("3")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pron)}</label><br />
                        <input {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_nlArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("4")}/>
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_nlArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_hlArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("5")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_hlArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_flArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("6")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_flArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_pflArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("12")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pflArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_ntArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("7")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_ntArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_ftArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("8")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_ftArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_pressureMoreLeft} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("9")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pressureMoreLeft)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_pressureMoreRight} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("10")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pressureMoreRight)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_flat} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("11")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_flat)}</label>
                    </p>
                    <p className='control'>
                        <label>Дясно ходило:</label>
                    </p>
                    <div className="radio-img-wrapper sup-pro">
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="rneutral" value="neutral" />
                            <label htmlFor="rneutral">
                            <img src={supProNeutral} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="rsupination" value="supination" />
                            <label htmlFor="rsupination">
                            <img src={supProSupination} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="roversupination" value="overSupination" />
                            <label htmlFor="roversupination">
                            <img src={supProOverSupination} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="roverpronation" value="overPronation" />
                            <label htmlFor="roverpronation">
                            <img src={supProOverPronation} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="rpronation" value="pronation" />
                            <label htmlFor="rpronation">
                            <img src={supProPronation} />
                            </label>
                        </div>
                    </div>
                    <p className='left-short'>
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_norm} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("1")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_norm)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_sup} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("2")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_sup)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_pron} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("3")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pron)}</label><br />
                        <input {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_nlArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("4")}/>
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_nlArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_hlArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("5")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_hlArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_flArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("6")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_flArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_pflArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("12")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pflArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_ntArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("7")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_ntArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_ftArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("8")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_ftArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_pressureMoreLeft} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("9")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pressureMoreLeft)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_pressureMoreRight} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("10")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pressureMoreRight)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_flat} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("11")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_flat)}</label>
                    </p>
                    <p className='control'>
                    <label>Варусна-валгусна деформация:</label>
                    </p>
                    <div className="radio-img-wrapper">
                        <div className="container">
                            <input {...register("feet_varus")} type="radio" id="varus_normal" value="normal" />
                            <label htmlFor="varus_normal">
                            <img src={varusNormal} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_varus")} type="radio" id="varus_bow" value="bow" />
                            <label htmlFor="varus_bow">
                            <img src={varusBow} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_varus")} type="radio" id="varus_knock" value="knock" />
                            <label htmlFor="varus_knock">
                            <img src={varusKnock} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_varus")} type="radio" id="varus_lbrk" value="leftBowRightKnock" />
                            <label htmlFor="varus_lbrk">
                            <img src={varusLBRK} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_varus")} type="radio" id="varus_lkrb" value="leftKnockRightBow" />
                            <label htmlFor="varus_lkrb">
                            <img src={varusLKRB} />
                            </label>
                        </div>
                    </div>
                    <p className='control'>
                        <label>Височина на сводове:</label>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label htmlFor='feet_arch_left'>Ляв:</label>
                            <input
                                {...register("feet_arch_left", {valueAsNumber: true})}
                                type="number"
                                name="feet_arch_left"
                                step="any"
                            />
                        </p>
                        <p className='control short'>
                            <label htmlFor='feet_arch_right'>Дeсен:</label>
                            <input
                                {...register("feet_arch_right", {valueAsNumber: true})}
                                type="number"
                                name="feet_arch_right"
                                step="any"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <label htmlFor='feet_recommendations'>Препоръки:</label>
                        <input
                            {...register("feet_recommendations")}
                            name="feet_recommendations"
                        />
                    </p>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Метаболизъм</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Гръбнак ⇢</button>
                    </div>
                    </>)}

                    {formStep === 6 && (
                        <>
                    <h2>Диагностика на гръбначен стълб</h2>
                    <h3>Ъглометрия шиен дял:</h3>
                    <img src={neck} className='form-img' />
                    <p className='control'>
                        <label htmlFor='spine_neck_flexion'>Флексия (60°-70°):</label>
                        <input
                            {...register("spine_neck_flexion", {valueAsNumber: true})}
                            type="number"
                            name="spine_neck_flexion"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='spine_neck_extension'>Екстензия (40°-50°):</label>
                        <input
                            {...register("spine_neck_extension", {valueAsNumber: true})}
                            type="number"
                            name="spine_neck_extension"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label>Латерални наклони (20°-45°):</label>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label htmlFor='spine_neck_lateral_left'>Ляво:</label>
                            <input
                                {...register("spine_neck_lateral_left", {valueAsNumber: true})}
                                type="number"
                                name="spine_neck_lateral_left"
                                step="any"
                            />
                        </p>
                        <p className='control short'>
                            <label htmlFor='spine_neck_lateral_right'>Дясно:</label>
                            <input
                                {...register("spine_neck_lateral_right", {valueAsNumber: true})}
                                type="number"
                                name="spine_neck_lateral_right"
                                step="any"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <label>Ротация (70°-90°):</label>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label htmlFor='spine_neck_rotation_left'>Ляво:</label>
                            <input
                                {...register("spine_neck_rotation_left", {valueAsNumber: true})}
                                type="number"
                                name="spine_neck_rotation_left"
                                step="any"
                            />
                        </p>
                        <p className='control short'>
                            <label htmlFor='spine_neck_rotation_right'>Дясно:</label>
                            <input
                                {...register("spine_neck_rotation_right", {valueAsNumber: true})}
                                type="number"
                                name="spine_neck_rotation_right"
                                step="any"
                            />
                        </p>
                    </div>
                    <h3>Общ преглед на гръбначния стълб:</h3>
                    <div className='control' style={{marginBottom:'1em'}}>
                        <label htmlFor='spine_xRay'>Рентгенова снимка:</label>
                        {/*<input*/}
                        {/*    {...register("spine_xRay")}*/}
                        {/*    name="spine_xRay"*/}
                        {/*    type="file"*/}
                        {/*    multiple*/}
                        {/*    accept=".jpg,.jpeg,.png"*/}
                        {/*    onChange={e => handleXRayFileRead(e)}*/}
                        {/*/>*/}
                        {/*<img src={uploadedXRayFile} style={{maxWidth: '300px', maxHeight: '300px', margin: '1em 0'}}/>*/}
                        {xRayFiles.map((field, index) => (
                            <div key={field.id} className='wider multi-inp' style={{margin: '0.3em 0'}}>
                                {/*<p>{`Снимка ${index}`}</p>*/}
                                <div className='card'>
                                        <input
                                            // important to include key with field's id

                                            {...register(`xRay.${index}.base64Image`)}
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            onChange={(e) => handleXRayFileRead(e, field, index, false)}
                                        />
                                </div>
                                <div className='card'><button type='button' className='del' style={{margin: '0 0 0 0.3em'}} onClick={() => removeXRay(index)}></button></div>
                                    {(field.base64Image && typeof field.base64Image != 'object') ? (
                                        <img
                                            src={field.base64Image}
                                            alt={`Рентгенова снимка ${index}`}
                                            style={{ maxWidth: '200px', maxHeight: '200px', margin: '1em 0 0.3em' }}
                                        />
                                    ) : (
                                        <img
                                            src={noXRayThumb}
                                            alt={`Няма снимка`}
                                            style={{ maxWidth: '200px', maxHeight: '100px', margin: '1em 0 0.3em' }}
                                        />
                                    )}
                            </div>
                        ))}
                        <div className='action' style={{margin: '0.6em 0'}}>
                            <button type='button' className='' onClick={() => {
                                appendXRay({base64Image: ''})
                            }}>
                                Добави още снимки
                            </button>
                        </div>
                    </div>
                    <div className="radio-img-wrapper spine-curv">
                        {/* <DiagnosticsSpineCheckbox num='1' curv='SpineN' register={register} handleCheckboxChange={handleCheckboxChange} getValues={getValues} /> */}
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineN" 
                                value={Spine.spineN} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("1")}
                            />
                            <label htmlFor="spineN">
                            <img src={spineN} />
                            </label>
                            <p>в норма</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLL" 
                                value={Spine.spineSLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("2")}
                            />
                            <label htmlFor="spineSLL">
                            <img src={spineSLL} />
                            </label>
                            <p>Сколиоза в лумбален дял наляво</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLR" 
                                value={Spine.spineSLR} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("3")}
                            />
                            <label htmlFor="spineSLR">
                            <img src={spineSLR} />
                            </label>
                            <p>Сколиоза в лумбален дял надясно</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSTL" 
                                value={Spine.spineSTL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("4")}
                            />
                            <label htmlFor="spineSTL">
                            <img src={spineSTL} />
                            </label>
                            <p>Сколиоза в торакален дял наляво</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSTR" 
                                value={Spine.spineSTR} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("5")}
                            />
                            <label htmlFor="spineSTR">
                            <img src={spineSTR} />
                            </label>
                            <p>Сколиоза в торакален дял надясно</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLRTL" 
                                value={Spine.spineSLRTL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("6")}
                            />
                            <label htmlFor="spineSLRTL">
                            <img src={spineSLRTL} />
                            </label>
                            <p>Сколиоза в лумбален дял надясно, в торакален дял наляво</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLRTR" 
                                value={Spine.spineSLRTR} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("9")}
                            />
                            <label htmlFor="spineSLRTR">
                            <img src={spineSLRTR} />
                            </label>
                            <p>Сколиоза в лумбален дял надясно, в торакален дял надясно</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLLTR" 
                                value={Spine.spineSLLTR} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("7")}
                            />
                            <label htmlFor="spineSLLTR">
                            <img src={spineSLLTR} />
                            </label>
                            <p>Сколиоза в лумбален дял наляво, в торакален дял надясно</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLLTL" 
                                value={Spine.spineSLLTL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("8")}
                            />
                            <label htmlFor="spineSLLTL">
                            <img src={spineSLLTL} />
                            </label>
                            <p>Сколиоза в лумбален дял наляво, в торакален дял наляво</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineK" 
                                value={Spine.spineK} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("10")}
                            />
                            <label htmlFor="spineK">
                            <img src={spineK} />
                            </label>
                            <p>Кифоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLL" 
                                value={Spine.spineLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("11")}
                            />
                            <label htmlFor="spineLL">
                            <img src={spineLL} />
                            </label>
                            <p>Изгладена поясна лордоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLLK" 
                                value={Spine.spineLLK} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("12")}
                            />
                            <label htmlFor="spineLLK">
                            <img src={spineLLK} />
                            </label>
                            <p>Изгладена поясна лордоза и кифоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLLKLC" 
                                value={Spine.spineLLKLC} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("13")}
                            />
                            <label htmlFor="spineLLKLC">
                            <img src={spineLLKLC} />
                            </label>
                            <p>Изгладена поясна лордоза, кифоза и изгладена шийна лородоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineHLL" 
                                value={Spine.spineHLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("14")}
                            />
                            <label htmlFor="spineHLL">
                            <img src={spineHLL} />
                            </label>
                            <p>Хипер лордоза в лумбален дял</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineHLLK" 
                                value={Spine.spineHLLK} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("15")}
                            />
                            <label htmlFor="spineHLLK">
                            <img src={spineHLLK} />
                            </label>
                            <p>Хипер лордоза в лумбален дял и кифоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineHLLKLC" 
                                value={Spine.spineHLLKLC} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("16")}
                            />
                            <label htmlFor="spineHLLKLC">
                            <img src={spineHLLKLC} />
                            </label>
                            <p>Хипер лордоза в лумбален дял, кифоза и изгладена шийна лордоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLC" 
                                value={Spine.spineLC} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("17")}
                            />
                            <label htmlFor="spineLC">
                            <img src={spineLC} />
                            </label>
                            <p>Изгладена шийна лордоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLCK" 
                                value={Spine.spineLCK} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("18")}
                            />
                            <label htmlFor="spineLCK">
                            <img src={spineLCK} />
                            </label>
                            <p>Изгладена шийна лордоза и кифоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLCHLL" 
                                value={Spine.spineLCHLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("19")}
                            />
                            <label htmlFor="spineLCHLL">
                            <img src={spineLCHLL} />
                            </label>
                            <p>Изгладена шийна лордоза и хипер лордоза в лумбален дял</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLCLL" 
                                value={Spine.spineLCLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("20")}
                            />
                            <label htmlFor="spineLCLL">
                            <img src={spineLCLL} />
                            </label>
                            <p>Изгладена шийна лордоза и изгладена поясна лордоза</p>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLCFLL" 
                                value={Spine.spineLCFLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("21")}
                            />
                            <label htmlFor="spineLCFLL">
                            <img src={spineLCFLL} />
                            </label>
                            <p>Изгладена шийна лордоза, плосък гръб и изгладена поясна лордоза</p>
                        </div>
                    </div>
                    <p className='control'>
                        <label htmlFor='spine_c7s1'>Измерване на От(C7-S1) 10см <br />(Измерване подвижността на гръбначния стълб)</label>
                        <input
                            {...register("spine_c7s1", {valueAsNumber: true})}
                            type="number"
                            name="spine_c7s1"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='spine_th12'>Измерване на От(Th12-S1) 7.5см <br />(Измерване подвижността на гръбначния стълб)</label>
                        <input
                            {...register("spine_th12", {valueAsNumber: true})}
                            type="number"
                            name="spine_th12"
                            step="any"
                        />
                    </p>
                    <p className='control'>
                        <label htmlFor='spine_c7th12'>Измерване на От(C7-Th12) 2.7см <br />(Измерване подвижността на гръбначния стълб)</label>
                        <input
                            {...register("spine_c7th12", {valueAsNumber: true})}
                            type="number"
                            name="spine_c7th12"
                            step="any"
                        />
                    </p>
                    <div className='control'>
                        <label>Шиен дял:</label>
                        <div className='control-select'>
                        <Controller
                            control={control}
                            render={({field:{onChange, value, name, ref}}) => (
                                <Select
                                    inputRef={ref}
                                    name={name}
                                    options={spineCervicalOptions}
                                    value={spineCervicalOptions.find(c => c.value === value)}
                                    onChange={(selectedOption) => {
                                        onChange(selectedOption.value);
                                        }}
                                    styles={selectCustomStyles}
                                />
                                )}
                            name='spine_cervical'
                        />
                        </div>
                    </div>
                    {/*<p className='control'>*/}
                    {/*    <label>Сила на захват (кг):</label>*/}
                    {/*</p>*/}
                    {/*<div className='control multi-inp'>*/}
                    {/*    <p className='control short'>*/}
                    {/*        <label htmlFor='spine_grip_left'>Ляво:</label>*/}
                    {/*        <input*/}
                    {/*            {...register("spine_grip_left", {valueAsNumber: true})}*/}
                    {/*            type="number"*/}
                    {/*            name="spine_grip_left"*/}
                    {/*            step="any"*/}
                    {/*        />*/}
                    {/*    </p>*/}
                    {/*    <p className='control short'>*/}
                    {/*        <label htmlFor='spine_grip_right'>Дясно:</label>*/}
                    {/*        <input*/}
                    {/*            {...register("spine_grip_right", {valueAsNumber: true})}*/}
                    {/*            type="number"*/}
                    {/*            name="spine_grip_right"*/}
                    {/*            step="any"*/}
                    {/*        />*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                            <p className='control'>
                                <input {...register("spine_handedness")}
                                       type="radio"
                                       value="левичар"
                                       id="lefthanded"
                                /><label className='lbl-radio' htmlFor="lefthanded">левичар</label>
                                <input {...register("spine_handedness")}
                                       type="radio"
                                       value="десничар"
                                       id="righthanded"
                                /><label className='lbl-radio' htmlFor="righthanded">десничар</label>
                            </p>
                    <div className='control'>
                        <label>Гръден дял:</label>
                            <input {...register("spine_thoracic_curv")} 
                                type="checkbox"
                                value={CurvModel.scoliosis}
                                defaultChecked={getValues('spine_thoracic_curv').includes('1')}
                                onChange={e => {handleCheckboxChange(e, 'spine_thoracic_curv'); setIsScolThorChecked(!isScolThorChecked)}}
                            /> {CurvModel.getCurvString(CurvModel.scoliosis)}
                            {isScolThorChecked && <>
                            <span style={{padding:'0 10px'}}><input
                                {...register("spine_thoracic_degrees", {valueAsNumber: true})}
                                type="number"
                                name="spine_thoracic_degrees"
                                className='tiny'
                                step="any"
                            /> °</span>
                            <input {...register("spine_thoracic_direction")} 
                                type="radio"
                                value="ляво"
                                   id="leftSpineThoracicDirection"
                            /><label className='lbl-radio' htmlFor="leftSpineThoracicDirection">ляво</label>
                            <input {...register("spine_thoracic_direction")} 
                                type="radio"
                                value="дясно"
                                id="rightSpineThoracicDirection"
                            /><label className='lbl-radio' htmlFor='rightSpineThoracicDirection'>дясно</label>
                        </>}
                        <span style={{marginLeft:'10px'}}><input {...register("spine_thoracic_curv")} 
                                type="checkbox"
                                value={CurvModel.kyphosis}
                                defaultChecked={getValues('spine_thoracic_curv').includes('2')}
                                onChange={e => handleCheckboxChange(e, 'spine_thoracic_curv')}
                            /> {CurvModel.getCurvString(CurvModel.kyphosis)}</span>
                    </div>
                            <p className='control'>
                                <label>Ключица:</label>
                            </p>
                            <div className='control multi-inp'>
                                <p className='control'>
                                    <span style={{padding:'0 10px'}}>
                                    <input
                                        {...register("spine_collarBone_degrees", {valueAsNumber: true})}
                                        type="number"
                                        name="spine_collarBone_degrees"
                                        step="any"
                                        className="tiny"
                                    /> °</span>
                                    <input {...register("spine_collarBone_direction")}
                                           type="radio"
                                           value="ляво"
                                           id="leftCollarBone"
                                    /><label className='lbl-radio' htmlFor="leftCollarBone">ляво</label>
                                    <input {...register("spine_collarBone_direction")}
                                           type="radio"
                                           value="дясно"
                                           id="rightCollarBone"
                                    /><label className='lbl-radio' htmlFor='rightCollarBone'>дясно</label>
                                </p>
                            </div>
                            <p className='control'>
                                <label>Рамене:</label>
                            </p>
                            <div className='control multi-inp'>
                                <p className='control'>
                                    <span style={{padding:'0 10px'}}>
                                    <input
                                        {...register("spine_shoulders_degrees", {valueAsNumber: true})}
                                        type="number"
                                        name="spine_shoulders_degrees"
                                        step="any"
                                        className="tiny"
                                    /> °</span>
                                    <input {...register("spine_shoulders_direction")}
                                           type="radio"
                                           value="ляво"
                                           id="leftShoulders"
                                    /><label className='lbl-radio' htmlFor="leftShoulders">ляво</label>
                                    <input {...register("spine_shoulders_direction")}
                                           type="radio"
                                           value="дясно"
                                           id="rightShoulders"
                                    /><label className='lbl-radio' htmlFor="rightShoulders">дясно</label>
                                </p>
                            </div>
                    <div className='control'>
                        <label>Лумбален дял:</label>
                        <div className='control-select'>
                        <Controller
                            control={control}
                            render={({field:{onChange, value, name, ref}}) => (
                                <Select
                                    inputRef={ref}
                                    name={name}
                                    options={spineLumbarOptions}
                                    value={spineLumbarOptions.find(l => l.value === value)}
                                    onChange={(selectedOption) => {
                                        onChange(selectedOption.value);
                                        }}
                                    styles={selectCustomStyles}
                                />
                                )}
                            name='spine_lumbar_lord'
                        />
                        </div>
                        <div style={{marginTop:'0.5em'}}>
                            <input {...register("spine_lumbar_curv")} 
                                type="checkbox"
                                value={CurvModel.scoliosis}
                                defaultChecked={getValues('spine_lumbar_curv').includes('1')}
                                onChange={e => {handleCheckboxChange(e, 'spine_lumbar_curv'); setIsScolLumbChecked(!isScolLumbChecked)}}
                            /> {CurvModel.getCurvString(CurvModel.scoliosis)}
                            {isScolLumbChecked && <>
                            <span style={{padding:'0 10px'}}><input
                                {...register("spine_lumbar_degrees", {valueAsNumber: true})}
                                type="number"
                                step="any"
                                name="spine_lumbar_degrees"
                                className='tiny'
                            /> °</span>
                            <input {...register("spine_lumbar_direction")} 
                                type="radio"
                                id="leftSpineLumbarDirection"
                                value="ляво"
                            /><label className='lbl-radio' htmlFor='leftSpineLumbarDirection'>ляво</label>
                            <input {...register("spine_lumbar_direction")} 
                                type="radio"
                                id="rightSpineLumbarDirection"
                                value="дясно"
                            /><label className='lbl-radio' htmlFor='rightSpineLumbarDirection'>дясно</label>
                            </>}
                        </div>
                    </div>
                    <p className='control'>
                        <label>Латерални наклони (30°):</label>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label htmlFor='spine_lateral_left'>Ляво:</label>
                            <input
                                {...register("spine_lateral_left", {valueAsNumber: true})}
                                type="number"
                                step="any"
                                name="spine_lateral_left"
                            />
                        </p>
                        <p className='control short'>
                            <label htmlFor='spine_lateral_right'>Дясно:</label>
                            <input
                                {...register("spine_lateral_right", {valueAsNumber: true})}
                                type="number"
                                step="any"
                                name="spine_lateral_right"
                            />
                        </p>
                    </div>
                    <div className='control'>
                        <label>Сакрален дял:</label>
                        {/* <input
                            {...register("spine_sacrum")}
                            name="spine_sacrum"
                        /> */}
                        {!isScolLumbChecked ? <p>Няма изменение.</p> : (spineLumbarDirection === 'ляво' ? <p>Тазът се усуква наляво.</p> : <p>Тазът се усуква надясно.</p>)}
                    </div>
                    {/* <p className='control'>
                        <label>Препоръки:</label>
                        <input
                            {...register("spine_recommendations")}
                            name="spine_recommendations"
                        />
                    </p> */}
                    <p className='control'>
                        <label htmlFor='spine_scoliometerNote'>Бележка (сколиометър):</label>
                        <textarea
                            {...register("spine_scoliometerNote")}
                            name="spine_scoliometerNote"
                            rows='3'
                        />
                    </p>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Ходила</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Подвижност ⇢</button>
                    </div>
                    </>)}

                    {formStep === 7 && 
                        <>
                    <h2>Ъглометрия - подвижност на стави</h2>
                    <h3>Тазобедрена става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (15°-0°-125°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_hip_left_extension", {valueAsNumber: true})}
                                        name="goniometry_hip_left_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_left_flexion", {valueAsNumber: true})}
                                        name="goniometry_hip_left_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_hip_right_extension", {valueAsNumber: true})}
                                        name="goniometry_hip_right_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_right_flexion", {valueAsNumber: true})}
                                        name="goniometry_hip_right_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                <label>С изпънато коляно (90°):</label>
                                    <input
                                        {...register("goniometry_hip_left_flexionStrKnee", {valueAsNumber: true})}
                                        name="goniometry_hip_left_flexionStrKnee"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                <label>С изпънато коляно (90°):</label>
                                    <input
                                        {...register("goniometry_hip_right_flexionStrKnee", {valueAsNumber: true})}
                                        name="goniometry_hip_right_flexionStrKnee"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Абдукция-Аддукция (45°-0°-30°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_hip_left_abduction", {valueAsNumber: true})}
                                        name="goniometry_hip_left_abduction"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_left_adduction", {valueAsNumber: true})}
                                        name="goniometry_hip_left_adduction"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_hip_right_abduction", {valueAsNumber: true})}
                                        name="goniometry_hip_right_abduction"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_right_adduction", {valueAsNumber: true})}
                                        name="goniometry_hip_right_adduction"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Вътрешна-Външна Ротация (45°-0°-45°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_hip_left_rotationInner", {valueAsNumber: true})}
                                        name="goniometry_hip_left_rotationInner"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_left_rotationOuter", {valueAsNumber: true})}
                                        name="goniometry_hip_left_rotationOuter"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_hip_right_rotationInner", {valueAsNumber: true})}
                                        name="goniometry_hip_right_rotationInner"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_right_rotationOuter", {valueAsNumber: true})}
                                        name="goniometry_hip_right_rotationOuter"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsHip} className="goniometry-img"/></div>
                            <div><img src={jointsHip1} className="goniometry-img" /></div>
                            <div><img src={jointsHip2} className="goniometry-img" /></div>
                        </div>
                    </div>
                    <h3>Раменна става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (60°-0°-180°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_shoulder_left_extension", {valueAsNumber: true})}
                                        name="goniometry_shoulder_left_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_left_flexion", {valueAsNumber: true})}
                                        name="goniometry_shoulder_left_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_shoulder_right_extension", {valueAsNumber: true})}
                                        name="goniometry_shoulder_right_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_right_flexion", {valueAsNumber: true})}
                                        name="goniometry_shoulder_right_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Абдукция-Аддукция (180°-0°-0°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_shoulder_left_abduction", {valueAsNumber: true})}
                                        name="goniometry_shoulder_left_abduction"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_left_adduction", {valueAsNumber: true})}
                                        name="goniometry_shoulder_left_adduction"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_shoulder_right_abduction", {valueAsNumber: true})}
                                        name="goniometry_shoulder_right_abduction"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_right_adduction", {valueAsNumber: true})}
                                        name="goniometry_shoulder_right_adduction"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Вътрешна-Външна Ротация (90°-0°-90°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_shoulder_left_rotationInner", {valueAsNumber: true})}
                                        name="goniometry_shoulder_left_rotationInner"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_left_rotationOuter", {valueAsNumber: true})}
                                        name="goniometry_shoulder_left_rotationOuter"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_shoulder_right_rotationInner", {valueAsNumber: true})}
                                        name="goniometry_shoulder_right_rotationInner"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_right_rotationOuter", {valueAsNumber: true})}
                                        name="goniometry_shoulder_right_rotationOuter"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsShoulder} className="goniometry-img"/></div>
                            <div><img src={jointsShoulder1} className="goniometry-img" /></div>
                            <div><img src={jointsShoulder2} className="goniometry-img" /></div>
                        </div>
                    </div>
                    <h3>Глезенна става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Дорзална флексия-Плантарна флексия (26°-0°-48°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_ankle_left_flexion", {valueAsNumber: true})}
                                        name="goniometry_ankle_left_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_ankle_left_extension", {valueAsNumber: true})}
                                        name="goniometry_ankle_left_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_ankle_right_flexion", {valueAsNumber: true})}
                                        name="goniometry_ankle_right_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_ankle_right_extension", {valueAsNumber: true})}
                                        name="goniometry_ankle_right_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsAnkle} className="goniometry-img"/></div>
                        </div>
                    </div>
                    <h3>Колянна става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (0°-0°-130°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_knee_left_extension", {valueAsNumber: true})}
                                        name="goniometry_knee_left_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_knee_left_flexion", {valueAsNumber: true})}
                                        name="goniometry_knee_left_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_knee_right_extension", {valueAsNumber: true})}
                                        name="goniometry_knee_right_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_knee_right_flexion", {valueAsNumber: true})}
                                        name="goniometry_knee_right_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsKnee} className="goniometry-img"/></div>
                        </div>
                    </div>
                    <h3>Лакътна става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (0°-0°-145°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_elbow_left_extension", {valueAsNumber: true})}
                                        name="goniometry_elbow_left_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_elbow_left_flexion", {valueAsNumber: true})}
                                        name="goniometry_elbow_left_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_elbow_right_extension", {valueAsNumber: true})}
                                        name="goniometry_elbow_right_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_elbow_right_flexion", {valueAsNumber: true})}
                                        name="goniometry_elbow_right_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Супинация-Пронация (90°-0°-90°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_elbow_left_supination", {valueAsNumber: true})}
                                        name="goniometry_elbow_left_supination"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_elbow_left_pronation", {valueAsNumber: true})}
                                        name="goniometry_elbow_left_pronation"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_elbow_right_supination", {valueAsNumber: true})}
                                        name="goniometry_elbow_right_supination"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_elbow_right_pronation", {valueAsNumber: true})}
                                        name="goniometry_elbow_right_pronation"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsElbow} className="goniometry-img"/></div>
                            <div><img src={jointsElbow1} className="goniometry-img"/></div>
                        </div>
                    </div>
                    <h3>Става на китката</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (70°-0°-90°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_wrist_left_extension", {valueAsNumber: true})}
                                        name="goniometry_wrist_left_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_wrist_left_flexion", {valueAsNumber: true})}
                                        name="goniometry_wrist_left_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_wrist_right_extension", {valueAsNumber: true})}
                                        name="goniometry_wrist_right_extension"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_wrist_right_flexion", {valueAsNumber: true})}
                                        name="goniometry_wrist_right_flexion"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Радиално-Улнарно отвеждане (20°-0°-40°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_wrist_left_radial", {valueAsNumber: true})}
                                        name="goniometry_wrist_left_radial"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_wrist_left_ulnar", {valueAsNumber: true})}
                                        name="goniometry_wrist_left_ulnar"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_wrist_right_radial", {valueAsNumber: true})}
                                        name="goniometry_wrist_right_radial"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_wrist_right_ulnar", {valueAsNumber: true})}
                                        name="goniometry_wrist_right_ulnar"
                                        type="number"
                                        step="any"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsWrist} className="goniometry-img-s"/></div>
                            <div><img src={jointsWrist1} className="goniometry-img-s"/></div>
                        </div>
                    </div>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Гръбнак</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Силометрия ⇢</button>
                    </div>
                    </>
                    }

                {formStep === 8 &&
                    <>
                        <h2>Силометрия</h2>
                        <p className='control'>
                            <label htmlFor='forcemetry_backExt'>Екстензори на гърба (кг):</label>
                            <input
                                {...register("forcemetry_backExt", {valueAsNumber: true})}
                                type="number"
                                name="forcemetry_backExt"
                                step="any"
                            />
                        </p>
                        <InputLR register={register} type="number" title="Трапец (кг)" left="forcemetry_trapezius_left" right="forcemetry_trapezius_right" />
                        <p className='control'>
                            <label htmlFor='forcemetry_neckExt'>Екстензори на врата (кг):</label>
                            <input
                                {...register("forcemetry_neckExt", {valueAsNumber: true})}
                                type="number"
                                name="forcemetry_neckExt"
                                step="any"
                            />
                        </p>
                        <p className='control'>
                            <label htmlFor='forcemetry_neckFlex'>Флексори на врата (кг):</label>
                            <input
                                {...register("forcemetry_neckFlex", {valueAsNumber: true})}
                                type="number"
                                name="forcemetry_neckFlex"
                                step="any"
                            />
                        </p>
                        <InputLR register={register} type="number" title="Латерални наклони на врата (кг)" left="forcemetry_neckLateral_left" right="forcemetry_neckLateral_right" />
                        <InputLR register={register} type="number" title="Teres major (кг)" left="forcemetry_teresMajor_left" right="forcemetry_teresMajor_right" />
                        <InputLR register={register} type="number" title="Teres minor (кг)" left="forcemetry_teresMinor_left" right="forcemetry_teresMinor_right" />
                        <InputLR register={register} type="number" title="Сила на захват (кг)" left="spine_grip_left" right="spine_grip_right" />
                        <InputLR register={register} type="number" title="Бицепс (кг)" left="forcemetry_biceps_left" right="forcemetry_biceps_right" />
                        <InputLR register={register} type="number" title="Трицепс (кг)" left="forcemetry_triceps_left" right="forcemetry_triceps_right" />
                        <InputLR register={register} type="number" title="Екстензори на китката (кг)" left="forcemetry_wristExt_left" right="forcemetry_wristExt_right" />
                        <InputLR register={register} type="number" title="Флексори на китката (кг)" left="forcemetry_wristFlex_left" right="forcemetry_wristFlex_right" />
                        <InputLR register={register} type="number" title="Латисмус Дорзи (кг)" left="forcemetry_latDorsi_left" right="forcemetry_latDorsi_right" />
                        <InputLR register={register} type="number" title="Предно рамо (кг)" left="forcemetry_shoulderFront_left" right="forcemetry_shoulderFront_right" />
                        <InputLR register={register} type="number" title="Средно рамо (кг)" left="forcemetry_shoulderMid_left" right="forcemetry_shoulderMid_right" />
                        <InputLR register={register} type="number" title="Задно рамо (кг)" left="forcemetry_shoulderBack_left" right="forcemetry_shoulderBack_right" />
                        <InputLR register={register} type="number" title="Гръдни мускули (кг)" left="forcemetry_chest_left" right="forcemetry_chest_right" />
                        <p className='control'>
                            <label htmlFor='backExt'>Коремни мускули (кг):</label>
                            <input
                                {...register("forcemetry_abdomen", {valueAsNumber: true})}
                                type="number"
                                name="forcemetry_abdomen"
                                step="any"
                            />
                        </p>
                        <InputLR register={register} type="number" title="Латерален наклон на кръста (кг)" left="forcemetry_waistLat_left" right="forcemetry_waistLat_right" />
                        <InputLR register={register} type="number" title="Глутеус (кг)" left="forcemetry_gluteus_left" right="forcemetry_gluteus_right" />
                        <InputLR register={register} type="number" title="Задно бедро (кг)" left="forcemetry_backHip_left" right="forcemetry_backHip_right" />
                        <InputLR register={register} type="number" title="Подбедрица (кг)" left="forcemetry_shank_left" right="forcemetry_shank_right" />
                        <InputLR register={register} type="number" title="Предно бедро (кг)" left="forcemetry_hip_left" right="forcemetry_hip_right" />
                        <InputLR register={register} type="number" title="Тибиалис (кг)" left="forcemetry_tibialis_left" right="forcemetry_tibialis_right" />
                        <InputLR register={register} type="number" title="Абдуктори (кг)" left="forcemetry_abductors_left" right="forcemetry_abductors_right" />
                        <InputLR register={register} type="number" title="Аддуктори (кг)" left="forcemetry_adductors_left" right="forcemetry_adductors_right" />
                        <div className='action'>
                            <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Подвижност</button>
                            <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Еластичност ⇢</button>
                        </div>
                    </>
                }

                    {formStep === 9 &&
                        <>
                    <h2>Оценка за еластичност на основни сухожилия и мускули</h2>
                    <div className='multi-inp wider top'>
                        <div className='left half'>
                            <div className='control-single-line'>
                                <label>Трапец</label>
                                {getValues('forcemetry_trapezius_left') &&
                                getValues('forcemetry_trapezius_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_trapezius_left');
                                        const rightForce = getValues('forcemetry_trapezius_right');
                                        const shorteningResult = musclesEvaluationSingleShortening('', 'флексия', 60, 70, getValues('spine_neck_flexion'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluationSingle('', 'флексия', 60, 70, getValues('spine_neck_flexion'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Стерноклеидомастоиден мускул</label>
                                {(getValues('spine_shoulders_degrees') && getValues('spine_shoulders_direction')) ? (
                                    <>
                                    {getValues('spine_shoulders_direction') === 'ляво' ?
                                            'скъсяване отляво' : 'скъсяване отдясно'
                                    }
                                    {musclesEvaluationSingleWeakness('', 'екстензия', 40, 50, getValues('spine_neck_extension')) &&
                                        `, ${musclesEvaluationSingleWeakness('', 'екстензия', 40, 50, getValues('spine_neck_extension'))}`}
                                    </>
                                ) : (
                                    musclesEvaluationSingle('', 'екстензия', 40, 50, getValues('spine_neck_extension'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Splenius Capitis</label>
                                {(elasticityLevatur === '1' || elasticityLevatur === '' || elasticityLevatur === null) ? (
                                        getValues('spine_shoulders_degrees') && getValues('spine_shoulders_direction')) ? (
                                        <>
                                            {getValues('spine_shoulders_direction') === 'ляво' ?
                                                'скъсяване отдясно' : 'скъсяване отляво'
                                            }
                                            {musclesEvaluationLatRotWeakness('', 'ротация', 70, 90, getValues('spine_neck_rotation_left'), getValues('spine_neck_rotation_right')) &&
                                                `, ${musclesEvaluationLatRotWeakness('', 'ротация', 70, 90, getValues('spine_neck_rotation_left'), getValues('spine_neck_rotation_right'))}`}
                                        </>
                                    ) : (
                                        musclesEvaluationLatRot('', 'ротация', 70, 90, getValues('spine_neck_rotation_left'), getValues('spine_neck_rotation_right'))
                                    ) :
                                    // musclesEvaluationLatRot('', 'латерални наклони в шиен дял', 20, 45, getValues('spine_neck_lateral_left'), getValues('spine_neck_lateral_right')) :
                                    LevaturModel.getLevaturString(getValues('elasticity_levatur'))
                                }
                            </div>
                            <div className='control-single-line'>
                                <label>Splenius Cervicis</label>
                                {(elasticityLevatur === '1' || elasticityLevatur === '' || elasticityLevatur === null) ? (
                                        getValues('spine_shoulders_degrees') && getValues('spine_shoulders_direction')) ? (
                                        <>
                                            {getValues('spine_shoulders_direction') === 'ляво' ?
                                                'скъсяване отдясно' : 'скъсяване отляво'
                                            }
                                            {musclesEvaluationLatRotWeakness('', 'ротация', 70, 90, getValues('spine_neck_rotation_left'), getValues('spine_neck_rotation_right')) &&
                                                `, ${musclesEvaluationLatRotWeakness('', 'ротация', 70, 90, getValues('spine_neck_rotation_left'), getValues('spine_neck_rotation_right'))}`}
                                        </>
                                    ) : (
                                        musclesEvaluationLatRot('', 'ротация', 70, 90, getValues('spine_neck_rotation_left'), getValues('spine_neck_rotation_right'))
                                    ) :
                                    // musclesEvaluationLatRot('', 'латерални наклони в шиен дял', 20, 45, getValues('spine_neck_lateral_left'), getValues('spine_neck_lateral_right')) :
                                    LevaturModel.getLevaturString(getValues('elasticity_levatur'))
                                }
                            </div>
                            <div className='control-single-line'>
                                <label>Levatur Scapulae</label>
                                {(elasticityLevatur === '1' || elasticityLevatur === '' || elasticityLevatur === null) ? (
                                    getValues('spine_shoulders_degrees') && getValues('spine_shoulders_direction')) ? (
                                        <>
                                            {getValues('spine_shoulders_direction') === 'ляво' ?
                                                'скъсяване отдясно' : 'скъсяване отляво'
                                            }
                                            {musclesEvaluationLatRotWeakness('', 'латерални наклони в шиен дял', 20, 45, getValues('spine_neck_lateral_left'), getValues('spine_neck_lateral_right')) &&
                                                `, ${musclesEvaluationLatRotWeakness('', 'латерални наклони в шиен дял', 20, 45, getValues('spine_neck_lateral_left'), getValues('spine_neck_lateral_right'))}`}
                                        </>
                                    ) : (
                                        musclesEvaluationLatRot('', 'латерални наклони в шиен дял', 20, 45, getValues('spine_neck_lateral_left'), getValues('spine_neck_lateral_right'))
                                    ) :
                                    // musclesEvaluationLatRot('', 'латерални наклони в шиен дял', 20, 45, getValues('spine_neck_lateral_left'), getValues('spine_neck_lateral_right')) :
                                    LevaturModel.getLevaturString(getValues('elasticity_levatur'))
                                }
                            </div>
                            <ElasticitySelect
                                control={control}
                                name={'elasticity_levatur'}
                                sublabel={'Корекция на Levatur Scapulae:'}
                                options={elasticityLevaturOptions}
                                onChange={handleSelectChange}
                            />
                            {/*}*/}
                            <div className='control-single-line'>
                                <label>Platysma</label>
                                {musclesEvaluationSingle('', 'екстензия', 40, 50, getValues('spine_neck_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Гръбни мускули</label>
                                {(getValues('spine_lumbar_curv').length > 0 || getValues('spine_thoracic_curv').length > 0 || (getValues('spine_curvature').length > 0 && !getValues('spine_curvature').includes('1'))) ? 
                                <span>мускулен дисбаланс</span> : <span>в норма</span>
                                }
                            </div>
                            <div className='control-single-line'>
                                <label>Quadratus Lumborum</label>
                                {getValues('forcemetry_waistLat_left') &&
                                getValues('forcemetry_waistLat_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_waistLat_left');
                                        const rightForce = getValues('forcemetry_waistLat_right');
                                        const shorteningResult = musclesEvaluationLatRotShortening('','латерални наклони в гръбначен стълб',30,30, getValues('spine_lateral_left'), getValues('spine_lateral_right'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluationLatRot('','латерални наклони в гръбначен стълб',30,30, getValues('spine_lateral_left'), getValues('spine_lateral_right'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Предно рамо</label>
                                {getValues('forcemetry_shoulderFront_left') &&
                                getValues('forcemetry_shoulderFront_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_shoulderFront_left');
                                        const rightForce = getValues('forcemetry_shoulderFront_right');
                                        const shorteningResult = musclesEvaluationShortening('','екстензия',0,60, getValues('goniometry_shoulder_left_extension'), getValues('goniometry_shoulder_right_extension'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'екстензия', 0, 60, getValues('goniometry_shoulder_left_extension'), getValues('goniometry_shoulder_right_extension'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Задно рамо</label>
                                {getValues('forcemetry_shoulderBack_left') &&
                                getValues('forcemetry_shoulderBack_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_shoulderBack_left');
                                        const rightForce = getValues('forcemetry_shoulderBack_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 180, getValues('goniometry_shoulder_left_flexion'), getValues('goniometry_shoulder_right_flexion'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'флексия', 0, 180, getValues('goniometry_shoulder_left_flexion'), getValues('goniometry_shoulder_right_flexion'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Latisimus Dorsi</label>
                                {getValues('forcemetry_latDorsi_left') &&
                                getValues('forcemetry_latDorsi_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_latDorsi_left');
                                        const rightForce = getValues('forcemetry_latDorsi_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 180, getValues('goniometry_shoulder_left_flexion'), getValues('goniometry_shoulder_right_flexion'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'флексия', 0, 180, getValues('goniometry_shoulder_left_flexion'), getValues('goniometry_shoulder_right_flexion'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Гръдни мускули</label>
                                {getValues('forcemetry_chest_left') &&
                                getValues('forcemetry_chest_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_chest_left');
                                        const rightForce = getValues('forcemetry_chest_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'абдукция', 0, 180, getValues('goniometry_shoulder_left_abduction'), getValues('goniometry_shoulder_right_abduction'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'абдукция', 0, 180, getValues('goniometry_shoulder_left_abduction'), getValues('goniometry_shoulder_right_abduction'))
                                )}
                            </div>
                            <ElasticitySelect
                                control={control}
                                name={'elasticity_abdomen'}
                                label={'Коремни мускули'}
                                options={[
                                    { value: '1', label: 'в норма' },
                                    { value: '2', label: 'слаба мускулатура' },
                                ]} />
                            <div className='control-single-line'>
                                <label>Ахилесово сухожилие</label>
                                {getValues('forcemetry_shank_left') &&
                                getValues('forcemetry_shank_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_shank_left');
                                        const rightForce = getValues('forcemetry_shank_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 26, getValues('goniometry_ankle_left_flexion'), getValues('goniometry_ankle_right_flexion'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluationCentimetrics(getValues('centimetrics_shank_left'), getValues('centimetrics_shank_right'), getValues('goniometry_ankle_left_flexion'), getValues('goniometry_ankle_right_flexion'), 'флексия', 0, 26)
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Подбедрица</label>
                                {getValues('forcemetry_shank_left') &&
                                getValues('forcemetry_shank_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_shank_left');
                                        const rightForce = getValues('forcemetry_shank_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 26, getValues('goniometry_ankle_left_flexion'), getValues('goniometry_ankle_right_flexion'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluationCentimetrics(getValues('centimetrics_shank_left'), getValues('centimetrics_shank_right'), getValues('goniometry_ankle_left_flexion'), getValues('goniometry_ankle_right_flexion'), 'флексия', 0, 26)
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Тибиалис</label>
                                {getValues('forcemetry_tibialis_left') &&
                                getValues('forcemetry_tibialis_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_tibialis_left');
                                        const rightForce = getValues('forcemetry_tibialis_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Extensor Digitorium Longus</label>
                                {getValues('forcemetry_tibialis_left') &&
                                getValues('forcemetry_tibialis_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_tibialis_left');
                                        const rightForce = getValues('forcemetry_tibialis_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Peroneus Longus</label>
                                {getValues('forcemetry_tibialis_left') &&
                                getValues('forcemetry_tibialis_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_tibialis_left');
                                        const rightForce = getValues('forcemetry_tibialis_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Абдуктори долни крайници</label>
                                {getValues('forcemetry_abductors_left') &&
                                getValues('forcemetry_abductors_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_abductors_left');
                                        const rightForce = getValues('forcemetry_abductors_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'аддукция', 0, 30, getValues('goniometry_hip_left_adduction'), getValues('goniometry_hip_right_adduction'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'аддукция', 0, 30, getValues('goniometry_hip_left_adduction'), getValues('goniometry_hip_right_adduction'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Аддуктори долни крайници</label>
                                {getValues('forcemetry_adductors_left') &&
                                getValues('forcemetry_adductors_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_adductors_left');
                                        const rightForce = getValues('forcemetry_adductors_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'абдукция', 0, 45, getValues('goniometry_hip_left_abduction'), getValues('goniometry_hip_right_abduction'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'абдукция', 0, 45, getValues('goniometry_hip_left_abduction'), getValues('goniometry_hip_right_abduction'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Четириглав бедрен мускул</label>
                                {getValues('forcemetry_hip_left') &&
                                getValues('forcemetry_hip_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_hip_left');
                                        const rightForce = getValues('forcemetry_hip_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 15, getValues('goniometry_hip_left_extension'), getValues('goniometry_hip_right_extension'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluationCentimetrics(getValues('centimetrics_hip_left'), getValues('centimetrics_hip_right'), getValues('goniometry_hip_left_extension'), getValues('goniometry_hip_right_extension'), 'екстензия', 0, 15)
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Сухожилия и мускули на задно бедро</label>
                                {getValues('forcemetry_backHip_left') &&
                                getValues('forcemetry_backHip_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_backHip_left');
                                        const rightForce = getValues('forcemetry_backHip_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 90, getValues('goniometry_hip_left_flexionStrKnee'), getValues('goniometry_hip_right_flexionStrKnee'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluationCentimetrics(getValues('centimetrics_hip_left'), getValues('centimetrics_hip_right'), getValues('goniometry_hip_left_flexionStrKnee'), getValues('goniometry_hip_right_flexionStrKnee'), 'флексия', 0, 90)
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Глутеус</label>
                                {getValues('forcemetry_gluteus_left') &&
                                getValues('forcemetry_gluteus_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_gluteus_left');
                                        const rightForce = getValues('forcemetry_gluteus_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 90, getValues('goniometry_hip_left_flexionStrKnee'), getValues('goniometry_hip_right_flexionStrKnee'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluationCentimetrics(getValues('centimetrics_hip_left'), getValues('centimetrics_hip_right'), getValues('goniometry_hip_left_flexionStrKnee'), getValues('goniometry_hip_right_flexionStrKnee'), 'флексия', 0, 90)
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Бицепс</label>
                                {getValues('forcemetry_biceps_left') &&
                                getValues('forcemetry_biceps_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_biceps_left');
                                        const rightForce = getValues('forcemetry_biceps_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 0, getValues('goniometry_elbow_left_extension'), getValues('goniometry_elbow_right_extension'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'екстензия', 0, 0, getValues('goniometry_elbow_left_extension'), getValues('goniometry_elbow_right_extension'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Трицепс</label>
                                {getValues('forcemetry_triceps_left') &&
                                getValues('forcemetry_triceps_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_triceps_left');
                                        const rightForce = getValues('forcemetry_triceps_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 145, getValues('goniometry_elbow_left_flexion'), getValues('goniometry_elbow_right_flexion'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'флексия', 0, 145, getValues('goniometry_elbow_left_flexion'), getValues('goniometry_elbow_right_flexion'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Флексори на предмишни мускули</label>
                                {getValues('forcemetry_wristFlex_left') &&
                                getValues('forcemetry_wristFlex_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_wristFlex_left');
                                        const rightForce = getValues('forcemetry_wristFlex_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 70, getValues('goniometry_wrist_left_extension'), getValues('goniometry_wrist_right_extension'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'екстензия', 0, 70, getValues('goniometry_wrist_left_extension'), getValues('goniometry_wrist_right_extension'))
                                )}
                            </div>
                            <div className='control-single-line'>
                                <label>Екстензори на предмишни мускули</label>
                                {getValues('forcemetry_wristExt_left') &&
                                getValues('forcemetry_wristExt_right') ? (
                                    (() => {
                                        const leftForce = getValues('forcemetry_wristExt_left');
                                        const rightForce = getValues('forcemetry_wristExt_right');
                                        const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 90, getValues('goniometry_wrist_left_flexion'), getValues('goniometry_wrist_right_flexion'));

                                        return (
                                            <>
                                                {shorteningResult && shorteningResult}
                                                {(shorteningResult && leftForce !== rightForce) && ', '}
                                                {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                            </>
                                        );
                                    })()
                                ) : (
                                    musclesEvaluation('', 'флексия', 0, 90, getValues('goniometry_wrist_left_flexion'), getValues('goniometry_wrist_right_flexion'))
                                )}
                            </div>
                        </div>
                        <div>
                            <div><img src={musclesFront} className="muscles-img" /></div>
                            <div><img src={musclesBack} className="muscles-img" /></div>
                        </div>
                    </div>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Силометрия</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Сантиметрия ⇢</button>
                    </div>
                    </>
                    }

                    {formStep === 10 &&
                        <>
                    <h2>Сантиметрия</h2>
                    <div className='control multi-inp wider'>
                        <div className='card'>
                            <img src={hip} className="control-img" />
                            <p className='control short'>
                                <h3>Бедро:</h3>
                                <label htmlFor='centimetrics_hip_left'>Ляво:</label>
                                <input
                                    {...register("centimetrics_hip_left", {valueAsNumber: true})}
                                    name="centimetrics_hip_left"
                                    type="number"
                                    step="any"
                                />
                                <label htmlFor='centimetrics_hip_right'>Дясно:</label>
                                <input
                                    {...register("centimetrics_hip_right", {valueAsNumber: true})}
                                    name="centimetrics_hip_right"
                                    type="number"
                                    step="any"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={shank} className="control-img" />
                            <p className='control short'>
                                <h3>Подбедрици:</h3>
                                <label htmlFor='centimetrics_shank_left'>Лява:</label>
                                <input
                                    {...register("centimetrics_shank_left", {valueAsNumber: true})}
                                    name="centimetrics_shank_left"
                                    type="number"
                                    step="any"
                                />
                                <label htmlFor='centimetrics_shank_right'>Дясна:</label>
                                <input
                                    {...register("centimetrics_shank_right", {valueAsNumber: true})}
                                    name="centimetrics_shank_right"
                                    type="number"
                                    step="any"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={shoulders} className="control-img" />
                            <p className='control short'>
                                <h3>Раменен пояс:</h3>
                                <input
                                    {...register("centimetrics_shoulders", {valueAsNumber: true})}
                                    name="centimetrics_shoulders"
                                    type="number"
                                    step="any"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={neck_measurement} className="control-img" />
                            <p className='control short'>
                                <h3>Врат:</h3>
                                <input
                                    {...register("centimetrics_neck", {valueAsNumber: true})}
                                    name="centimetrics_neck"
                                    type="number"
                                    step="any"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={abdomen} className="control-img" />
                            <p className='control short'>
                                <h3>Корем:</h3>
                                <input
                                    {...register("centimetrics_abdomen", {valueAsNumber: true})}
                                    name="centimetrics_abdomen"
                                    type="number"
                                    step="any"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={chest} className="control-img" />
                            <p className='control short'>
                                <h3>Гърди:</h3>
                                <input
                                    {...register("centimetrics_chest", {valueAsNumber: true})}
                                    name="centimetrics_chest"
                                    type="number"
                                    step="any"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={biceps} className="control-img" />
                            <p className='control short'>
                                <h3>Бицепс:</h3>
                                <label htmlFor='centimetrics_biceps_left'>Ляв:</label>
                                <input
                                    {...register("centimetrics_biceps_left", {valueAsNumber: true})}
                                    name="centimetrics_biceps_left"
                                    type="number"
                                    step="any"
                                />
                                <label htmlFor='centimetrics_biceps_right'>Десен:</label>
                                <input
                                    {...register("centimetrics_biceps_right", {valueAsNumber: true})}
                                    name="centimetrics_biceps_right"
                                    type="number"
                                    step="any"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={forearm} className="control-img" />
                            <p className='control short'>
                                <h3>Предмишница:</h3>
                                <label htmlFor='centimetrics_forearm_left'>Лява:</label>
                                <input
                                    {...register("centimetrics_forearm_left", {valueAsNumber: true})}
                                    name="centimetrics_forearm_left"
                                    type="number"
                                    step="any"
                                />
                                <label htmlFor='centimetrics_forearm_right'>Дясна:</label>
                                <input
                                    {...register("centimetrics_forearm_right", {valueAsNumber: true})}
                                    name="centimetrics_forearm_right"
                                    type="number"
                                    step="any"
                                />
                            </p>
                        </div>
                        <div className='card'></div>
                    </div>
                  
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Еластичност</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Кости ⇢</button>
                    </div>
                    </>
                    }

                    {formStep === 11 &&
                        <>
                    <h2>Измервания на дължината на костите на крайниците</h2>
                    <p className='control'>
                        <h3>Бедрена кост (Femur):</h3>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label htmlFor='boneLength_femur_left'>Ляв крак:</label>
                            <input
                                {...register("boneLength_femur_left", {valueAsNumber: true})}
                                name="boneLength_femur_left"
                                type="number"
                                step="any"
                            />
                        </p>
                        <div><img src={femur} className="control-img" /></div>
                        <p className='control short'>
                            <label htmlFor='boneLength_femur_right'>Десен крак:</label>
                            <input
                                {...register("boneLength_femur_right", {valueAsNumber: true})}
                                name="boneLength_femur_right"
                                type="number"
                                step="any"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <h3>Голям и малък пищял (Tibia):</h3>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label htmlFor='boneLength_tibia_left'>Ляв крак:</label>
                            <input
                                {...register("boneLength_tibia_left", {valueAsNumber: true})}
                                name="boneLength_tibia_left"
                                type="number"
                                step="any"
                            />
                        </p>
                        <div><img src={tibia} className="control-img" /></div>
                        <p className='control short'>
                            <label htmlFor='boneLength_tibia_right'>Десен крак:</label>
                            <input
                                {...register("boneLength_tibia_right", {valueAsNumber: true})}
                                name="boneLength_tibia_right"
                                type="number"
                                step="any"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <h3>Раменна кост (Humerus):</h3>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label htmlFor='boneLength_humerus_left'>Лява ръка:</label>
                            <input
                                {...register("boneLength_humerus_left", {valueAsNumber: true})}
                                name="boneLength_humerus_left"
                                type="number"
                                step="any"
                            />
                        </p>
                        <div><img src={humerus} className="control-img" /></div>
                        <p className='control short'>
                            <label htmlFor='boneLength_humerus_right'>Дясна ръка:</label>
                            <input
                                {...register("boneLength_humerus_right", {valueAsNumber: true})}
                                name="boneLength_humerus_right"
                                type="number"
                                step="any"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <h3>Лакътна и лъчева кост (Radius):</h3>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label htmlFor='boneLength_radius_left'>Лява ръка:</label>
                            <input
                                {...register("boneLength_radius_left", {valueAsNumber: true})}
                                name="boneLength_radius_left"
                                type="number"
                                step="any"
                            />
                        </p>
                        <div><img src={radius} className="control-img" /></div>
                        <p className='control short'>
                            <label htmlFor='boneLength_radius_right'>Дясна ръка:</label>
                            <input
                                {...register("boneLength_radius_right", {valueAsNumber: true})}
                                name="boneLength_radius_right"
                                type="number"
                                step="any"
                            />
                        </p>
                    </div>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Кости</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Термография ⇢</button>
                    </div>
                    </>
                    }

                    {formStep === 12 &&
                        <>
                            <h2>Термография</h2>
                            <ThermographyInput register={register} title="Гръбначен стълб" degreesMin="thermography_spine_degreesMin" degreesMax="thermography_spine_degreesMax" handleFileRead={handleImageRead} setField={setThermSpineImg} image="thermography_spine_image" thumb={thermSpineImg} note="thermography_spine_note" />
                            <ThermographyInput register={register} title="Шиен дял" degreesMin="thermography_neck_degreesMin" degreesMax="thermography_neck_degreesMax" handleFileRead={handleImageRead} setField={setThermNeckImg} image="thermography_neck_image" thumb={thermNeckImg} note="thermography_neck_note" />
                            <ThermographyInput register={register} title="Гръден дял" degreesMin="thermography_thoracic_degreesMin" degreesMax="thermography_thoracic_degreesMax" handleFileRead={handleImageRead} setField={setThermThoracicImg} image="thermography_thoracic_image" thumb={thermThoracicImg} note="thermography_thoracic_note" />
                            <ThermographyInput register={register} title="Лумбален дял" degreesMin="thermography_lumbar_degreesMin" degreesMax="thermography_lumbar_degreesMax" handleFileRead={handleImageRead} setField={setThermLumbarImg} image="thermography_lumbar_image" thumb={thermLumbarImg} note="thermography_lumbar_note" />
                            <ThermographyInput register={register} title="Сакрален дял" degreesMin="thermography_sacral_degreesMin" degreesMax="thermography_sacral_degreesMax" handleFileRead={handleImageRead} setField={setThermSacralImg} image="thermography_sacral_image" thumb={thermSacralImg} note="thermography_sacral_note" />
                            <ThermographyInput register={register} title="Десен горен крайник (латерално)" degreesMin="thermography_rArmLat_degreesMin" degreesMax="thermography_rArmLat_degreesMax" handleFileRead={handleImageRead} setField={setThermRArmLatImg} image="thermography_rArmLat_image" thumb={thermRArmLatImg} note="thermography_rArmLat_note" />
                            <ThermographyInput register={register} title="Десен горен крайник (медиално)" degreesMin="thermography_rArmMed_degreesMin" degreesMax="thermography_rArmMed_degreesMax" handleFileRead={handleImageRead} setField={setThermRArmMedImg} image="thermography_rArmMed_image" thumb={thermRArmMedImg} note="thermography_rArmMed_note" />
                            <ThermographyInput register={register} title="Ляв горен крайник (латерално)" degreesMin="thermography_lArmLat_degreesMin" degreesMax="thermography_lArmLat_degreesMax" handleFileRead={handleImageRead} setField={setThermLArmLatImg} image="thermography_lArmLat_image" thumb={thermLArmLatImg} note="thermography_lArmLat_note" />
                            <ThermographyInput register={register} title="Ляв горен крайник (медиално)" degreesMin="thermography_lArmMed_degreesMin" degreesMax="thermography_lArmMed_degreesMax" handleFileRead={handleImageRead} setField={setThermLArmMedImg} image="thermography_lArmMed_image" thumb={thermLArmMedImg} note="thermography_lArmMed_note" />
                            <ThermographyInput register={register} title="Лакътна става (латерално)" degreesMin="thermography_elbowLat_degreesMin" degreesMax="thermography_elbowLat_degreesMax" handleFileRead={handleImageRead} setField={setThermElbowLatImg} image="thermography_elbowLat_image" thumb={thermElbowLatImg} note="thermography_elbowLat_note" />
                            <ThermographyInput register={register} title="Лакътна става (медиално)" degreesMin="thermography_elbowMed_degreesMin" degreesMax="thermography_elbowMed_degreesMax" handleFileRead={handleImageRead} setField={setThermElbowMedImg} image="thermography_elbowMed_image" thumb={thermElbowMedImg} note="thermography_elbowMed_note" />
                            <ThermographyInput register={register} title="Киткена става (супинация)" degreesMin="thermography_wristSup_degreesMin" degreesMax="thermography_wristSup_degreesMax" handleFileRead={handleImageRead} setField={setThermWristSupImg} image="thermography_wristSup_image" thumb={thermWristSupImg} note="thermography_wristSup_note" />
                            <ThermographyInput register={register} title="Киткена става (пронация)" degreesMin="thermography_wristPro_degreesMin" degreesMax="thermography_wristPro_degreesMax" handleFileRead={handleImageRead} setField={setThermWristProImg} image="thermography_wristPro_image" thumb={thermWristProImg} note="thermography_wristPro_note" />
                            <ThermographyInput register={register} title="Раменна става (предно)" degreesMin="thermography_shoulderFront_degreesMin" degreesMax="thermography_shoulderFront_degreesMax" handleFileRead={handleImageRead} setField={setThermShoulderFrontImg} image="thermography_shoulderFront_image" thumb={thermShoulderFrontImg} note="thermography_shoulderFront_note" />
                            <ThermographyInput register={register} title="Раменна става (задно)" degreesMin="thermography_shoulderBack_degreesMin" degreesMax="thermography_shoulderBack_degreesMax" handleFileRead={handleImageRead} setField={setThermShoulderBackImg} image="thermography_shoulderBack_image" thumb={thermShoulderBackImg} note="thermography_shoulderBack_note" />
                            <ThermographyInput register={register} title="Десен долен крайник (отпред)" degreesMin="thermography_rLegFront_degreesMin" degreesMax="thermography_rLegFront_degreesMax" handleFileRead={handleImageRead} setField={setThermRLegFrontImg} image="thermography_rLegFront_image" thumb={thermRLegFrontImg} note="thermography_rLegFront_note" />
                            <ThermographyInput register={register} title="Ляв долен крайник (отпред)" degreesMin="thermography_lLegFront_degreesMin" degreesMax="thermography_lLegFront_degreesMax" handleFileRead={handleImageRead} setField={setThermLLegFrontImg} image="thermography_lLegFront_image" thumb={thermLLegFrontImg} note="thermography_lLegFront_note" />
                            <ThermographyInput register={register} title="Колянна става (отпред)" degreesMin="thermography_kneeFront_degreesMin" degreesMax="thermography_kneeFront_degreesMax" handleFileRead={handleImageRead} setField={setThermKneeFrontImg} image="thermography_kneeFront_image" thumb={thermKneeFrontImg} note="thermography_kneeFront_note" />
                            <ThermographyInput register={register} title="ТБС" degreesMin="thermography_hip_degreesMin" degreesMax="thermography_hip_degreesMax" handleFileRead={handleImageRead} setField={setThermHipImg} image="thermography_hip_image" thumb={thermHipImg} note="thermography_hip_note" />
                            <ThermographyInput register={register} title="Глезенна става" degreesMin="thermography_ankle_degreesMin" degreesMax="thermography_ankle_degreesMax" handleFileRead={handleImageRead} setField={setThermAnkleImg} image="thermography_ankle_image" thumb={thermAnkleImg} note="thermography_ankle_note" />
                            <ThermographyInput register={register} title="Десен долен крайник (отзад)" degreesMin="thermography_rLegBack_degreesMin" degreesMax="thermography_rLegBack_degreesMax" handleFileRead={handleImageRead} setField={setThermRLegBackImg} image="thermography_rLegBack_image" thumb={thermRLegBackImg} note="thermography_rLegBack_note" />
                            <ThermographyInput register={register} title="Ляв долен крайник (отзад)" degreesMin="thermography_lLegBack_degreesMin" degreesMax="thermography_lLegBack_degreesMax" handleFileRead={handleImageRead} setField={setThermLLegBackImg} image="thermography_lLegBack_image" thumb={thermLLegBackImg} note="thermography_lLegBack_note" />
                            <ThermographyInput register={register} title="Колянна става (сгъвка)" degreesMin="thermography_kneeBack_degreesMin" degreesMax="thermography_kneeBack_degreesMax" handleFileRead={handleImageRead} setField={setThermKneeBackImg} image="thermography_kneeBack_image" thumb={thermKneeBackImg} note="thermography_kneeBack_note" />
                            <ThermographyInput register={register} title="Ахилесово сухожилие" degreesMin="thermography_achilles_degreesMin" degreesMax="thermography_achilles_degreesMax" handleFileRead={handleImageRead} setField={setThermAchillesImg} image="thermography_achilles_image" thumb={thermAchillesImg} note="thermography_achilles_note" />
                            <div className='action'>
                                <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Кости</button>
                                <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Чувствителност ⇢</button>
                            </div>
                        </>
                    }

                    {formStep === 13 &&
                        <>
                            <h2>Тест за чувствителност</h2>
                            <div className="svg-container svg-edit">
                                <svg width="100%" height="100%" viewBox="0 0 229 800">
                                    {Object.entries(rightFront).map(([part, { d, fill }]) => (
                                        // <path key={part.key} d={part.d} fill={part.fill} onMouseEnter={() => setSelectedPart(part)} onClick={() => handlePartClick(part)} />
                                        <path key={part} d={d} fill={getValues(`sensitivity_right_${part}`) || "#b2b2b2"}
                                              onClick={
                                                  () => handlePartClick(`right_${part}`)
                                              } />
                                    ))}
                                </svg>
                            </div>
                            <div className="svg-container svg-edit">
                                <svg width="100%" height="100%" viewBox="0 0 229 800">
                                    {Object.entries(leftFront).map(([part, { d, fill }]) => (
                                        // <path key={part.key} d={part.d} fill={part.fill} onMouseEnter={() => setSelectedPart(part)} onClick={() => handlePartClick(part)} />
                                        <path key={part} d={d} fill={getValues(`sensitivity_left_${part}`) || "#b2b2b2"}
                                              onClick={
                                                  () => handlePartClick(`left_${part}`)
                                              } />
                                    ))}
                                </svg>
                            </div>
                            <div className="svg-container svg-edit">
                                <svg width="100%" height="100%" viewBox="0 0 229 800">
                                    {Object.entries(leftBack).map(([part, { d, fill }]) => (
                                        // <path key={part.key} d={part.d} fill={part.fill} onMouseEnter={() => setSelectedPart(part)} onClick={() => handlePartClick(part)} />
                                        <path key={part} d={d} fill={getValues(`sensitivity_left_${part}`) || "#b2b2b2"}
                                              onClick={
                                                  () => handlePartClick(`left_${part}`)
                                              } />
                                    ))}
                                </svg>
                            </div>
                            <div className="svg-container svg-edit">
                                <svg width="100%" height="100%" viewBox="0 0 229 800">
                                    {Object.entries(rightBack).map(([part, { d, fill }]) => (
                                        // <path key={part.key} d={part.d} fill={part.fill} onMouseEnter={() => setSelectedPart(part)} onClick={() => handlePartClick(part)} />
                                        <path key={part} d={d} fill={getValues(`sensitivity_right_${part}`) || "#b2b2b2"}
                                              onClick={
                                                  () => handlePartClick(`right_${part}`)
                                              } />
                                    ))}
                                </svg>
                            </div>
                            <p className='control'>
                                <label htmlFor='sensitivity_note'>Бележка:</label>
                                <input
                                    {...register("sensitivity_note")}
                                    name="sensitivity_note"
                                    step="any"
                                />
                            </p>
                            <div className='action'>
                                <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Термография</button>
                                <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Резултат ⇢</button>
                            </div>
                        </>
                    }

                {formStep !== 14 && (
                    <div className='action'>
                        <button className="cancel" type='button' onClick={() => navigate('/diagnostics/' + String(formValues.id))}>Отказ</button>
                        {/* <button className="finish" type='submit' onClick={handleSubmit(onFinish)}>Финализирай</button> */}
                        <button className="finish" type='submit'>Финализирай</button>
                    </div>
                    )}
                
                {formStep === 14 && (
                    <>
                    <DiagnosticsSheetData data={{values: formValues, logo: authCtx.user.company.logo, sensRightFront: rightFront}} />
                    <section className="lists">
                        <div className='action'>
                            <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Чувствителност</button>
                        </div>
                        <div className="action">
                            <button className="cancel" type='button' onClick={() => navigate('/diagnostics/' + String(formValues.id))}>Отказ</button>
                            {/* <button className="finish" type='submit' onClick={handleSubmit(onFinish)}>Финализирай</button> */}
                            <button className="finish" type='submit'>Финализирай</button>
                        </div>
                    </section>
                </>
                )}

            </section>
            </form>
        </>
    )
}

export default DiagnosticsForm;