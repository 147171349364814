import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import selectCustomStyles from '../../components/UI/SelectCustomStyles';
import ElasticityModel from '../../models/Elasticity';

// const muscleTendonOptions = [
//     { value: '1', label: ElasticityModel.getElasticityString('1') },
//     { value: '2', label: ElasticityModel.getElasticityString('2') },
//     { value: '3', label: ElasticityModel.getElasticityString('3') },
//     { value: '4', label: ElasticityModel.getElasticityString('4') },
//     { value: '5', label: ElasticityModel.getElasticityString('5') },
//     { value: '6', label: ElasticityModel.getElasticityString('6') },
//     { value: '7', label: ElasticityModel.getElasticityString('7') },
//     { value: '8', label: ElasticityModel.getElasticityString('8') },
//     { value: '9', label: ElasticityModel.getElasticityString('9') },
//   ];

// const muscleTendonOptions = [
//     { value: '1', label: 'в норма' },
//     { value: '2', label: 'слаба мускулатура' },
//   ];

const ElasticitySelect = ({control, name, label, sublabel, options, onChange}) => {

    return (
        <div className='control-single-line'>
            {label && <label>{label}</label>}
            <div className='control-select'>
            <span>{sublabel}</span>
            <Controller
                control={control}
                render={({field:{onChange: controllerOnChange, value, name, ref}}) => (
                    <Select
                        inputRef={ref}
                        name={name}
                        options={options}
                        value={options.find(m => m.value === value)}
                        onChange={(selectedOption) => {
                            controllerOnChange(selectedOption.value); // Update react-hook-form state
                            onChange(selectedOption); // Call the parent's onChange handler
                            }}
                        styles={selectCustomStyles}
                    />
                    )}
                name={name}
                // rules={{ required: true }}
            />
            </div>
        </div>
    );
};

export default ElasticitySelect;